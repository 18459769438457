import { Link } from "react-router-dom";

const Home = () => {
    return (
        <>
            <div className="grid sm:grid-cols-1 lg:grid-cols-2 justify-center relative items-center w-full h-full gap-5">
                <img className="w-[1000px] sm:max-w-full rounded-md" src="https://cdn.dribbble.com/userupload/12609824/file/original-0ebf1e6c9a64bfe1510cf8d72de99185.gif" alt="" />
                <h2 className="font-bold items-center text-8xl italic text-[#47bdff]"><Link to="/admin/portfolio">Go to Portfolio</Link></h2>
            </div>
        </>
    )
};
export default Home;