import React, { useState, useEffect } from 'react';
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { ThreeDots } from 'react-loader-spinner';
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../../assets/css/App.css";

const Auth = () => {
  const REACT_APP_USER = process.env.REACT_APP_USER;
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    if (Cookies.get('refreshToken')) {
      navigate("/admin/home");
    } else {
      navigate("/auth")
    }
  }, [navigate]);

  const validateForm = () => {
    const errors = {};
    if (!email.trim()) {
      errors.email = "Email is required";
    }
    if (password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }
    return errors;
  };

  const loginAction = (e) => {
    e.preventDefault();

    let hasError = false;
    const newErrors = {};

    if (e.target && e.target.elements) {
      ["email", "password"].forEach((field) => {
        const fieldValue = e.target.elements[field]?.value.trim();
        if (!fieldValue) {
          newErrors[field] = true;
          hasError = true;
        }
      });
    }

    if (hasError) {
      setErrors(newErrors);
      setButtonLoader(false);
      return;
    }

    setButtonLoader(true);
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      setButtonLoader(false);
      return;
    }
    axios.post(`${REACT_APP_USER}admin-login`, { email, password })
      .then((response) => {
        const token = response.data.refreshToken;
        Cookies.set('refreshToken', token, { expires: 1 });
        navigate("/admin/home");
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.errors) {
          setValidationErrors(error.response.data.errors);
        } else {
          setValidationErrors({ error: "Incorrect Email or Password" });
        }
      })
      .finally(() => {
        setButtonLoader(false);
      });
  };

  useEffect(() => {
    AOS.init({});
  }, []);

  return (
    <section className="">
      <div className="flex flex-col w-full items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 absolute z-10">
        <div className="w-full bg-[#ffffff00] rounded-lg shadow border-[#00000012] border md:mt-0 sm:max-w-md md:max-w-lg lg:max-w-2xl lg:max-h-2xl xl:p-0 backdrop-blur-md">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <div className="flex justify-center">
              <img className="w-44" src="https://codebeans.net/assets/images/codebeans-logo.svg" alt="logo" />
            </div>
            <h1 className="text-xl text-center font-bold leading-tight tracking-tight md:text-3xl text-[white]">
              Sign in to your account
            </h1>
            <form className="space-y-4 md:space-y-6" action="#" onSubmit={(e) => { loginAction(e) }}>
              {validationErrors.error &&
                <p className='text-center '><small className='text-[red] text-lg font-semibold'>{validationErrors.error}</small></p>
              }
              <div>
                <label htmlFor="email" className={`block mb-2 text-sm font-medium ${errors.email ? 'text-red-500' : 'text-white'}`}>Your email</label>
                <input type="email" name="email" id="email" className={`bg-gray-50 border-2  text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${errors.email ? 'border-red-500' : 'border-gray-300'}`} placeholder="name@company.com" value={email} onChange={(e) => { setEmail(e.target.value) }} required="" />
              </div>
              <div className='relative'>
                <label htmlFor="password" className={`block mb-2 text-sm font-medium ${errors.password ? 'text-red-500' : 'text-white'}`}>Password</label>
                <label className='relative'>
                  <input type={showPassword ? "text" : "password"} name="password" id="password" value={password} onChange={(e) => { setPassword(e.target.value) }} placeholder="Passw•••d" className={`bg-gray-50 border-2 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${errors.password ? 'border-red-500' : 'border-gray-300'}`} required="" />
                  <button type="button" onClick={() => setShowPassword(!showPassword)} className="absolute inset-y-0 right-0 text-xl flex items-center pr-3 text-black-600">
                    {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                  </button>
                </label>
              </div>
              <button type="submit" className="w-full text-white bg-secondary-400 hover:bg-secondary-600 focus:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center cursor-pointer ease-in-out duration-300" disabled={buttonLoader}>{buttonLoader ? <span className="flex justify-center gap-1">Loading<ThreeDots wrapperStyle={{ marginTop: "4px" }} visible={true} height="20" width="20" color="#ffffff" radius="9" ariaLabel="three-dots-loading" /></span> : "Sign in"}</button>
            </form>
          </div>
        </div>
      </div>
      <div className="area" >
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div >
    </section>
  );
}


export default Auth;