import React, { useState, useEffect } from 'react';
import PulseLoader from "react-spinners/PulseLoader";
import { BsFillPencilFill, BsPlusLg } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import Loadimg from './loader';
import axios from 'axios';

const REACT_APP_SERVICE = process.env.REACT_APP_SERVICE;

const Service = () => {

    const [showModal, setShowModal] = useState(false);
    const [showserviceModal, setShowServiceModal] = useState(false);
    const [buttonloader, setButtonLoader] = useState(false);
    const [selectedService, setSelectedService] = useState(null);
    const [loading, setLoading] = useState(true);
    const [servicedata, setServiceData] = useState([]);
    const [numField, setNumField] = useState(1);
    const [technumField, setTechNumField] = useState(1);

    const [errors, setErrors] = useState({});

    const [formData, setFormData] = useState({
        s_no: '',
        bannerimg: null,
        coverimg: null,
        title: '',
        description: '',
        sub_title: '',
        sub_description: '',
        points: [{
            heading: '',
            detail: '',
            image: null,
        }],
        technologyImg: []
    });

    const fetchData = async () => {
        try {
            const response = await axios.get(`${REACT_APP_SERVICE}all`);
            if (response.status === 200) {
                setServiceData(response.data);
                setLoading(false);
            } else {
                console.error('Error fetching data:', response.status);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const getNextSerialNumber = () => {
        const existingSerialNumbers = servicedata.map(item => item.s_no);
        const maxSerialNumber = Math.max(...existingSerialNumbers);
        return maxSerialNumber !== -Infinity ? maxSerialNumber + 1 : 1;
    };

    const handleFileChange = (e, index, field) => {
        const file = e.target.files[0];
        if (field === 'coverimg' || field === 'bannerimg') {
            setFormData({ ...formData, [field]: file });
        } else if (field === 'image') {
            const updatedPoints = [...formData.points];
            if (!updatedPoints[index]) {
                updatedPoints[index] = {};
            }
            updatedPoints[index].image = file;
            setFormData({ ...formData, points: updatedPoints });
        } else if (field === 'technologyImg') {
            const updatedTechImages = [...formData.technologyImg];
            updatedTechImages[index] = file;
            setFormData({ ...formData, technologyImg: updatedTechImages });
        }
    };

    const handleAddField = () => {
        setNumField(numField + 1);
        setFormData(prevState => ({
            ...prevState,
            points: [
                ...prevState.points,
                { points: '', heading: '', detail: '', image: null }
            ]
        }));
    };

    const handleRemoveField = () => {
        if (formData.points.length > 1) {
            setNumField(numField - 1);
            setFormData(prevState => ({
                ...prevState,
                points: prevState.points.slice(0, -1)
            }));
        }
        setNumField(numField > 1 ? numField - 1 : 1);
    };

    const handleAddTechField = () => {
        setTechNumField(technumField + 1);
    };

    const handleRemoveTechField = () => {
        setTechNumField(technumField > 1 ? technumField - 1 : 1);
    };

    const handleInputChange = (e, index, field) => {
        const value = e.target.value;
        const updatedPoints = [...formData.points];
        if (!updatedPoints[index]) {
            updatedPoints[index] = {};
        }
        updatedPoints[index][field] = value;
        setFormData({ ...formData, points: updatedPoints });
    };

    const handleCreate = async () => {
        let hasError = false;
        const newErrors = {};

        // Check required fields and update error state
        ['s_no', 'title', 'description', 'sub_title', 'sub_description', 'bannerimg', 'coverimg'].forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = true;
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }

        setButtonLoader(true);

        const uploadData = new FormData();
        Object.entries(formData).forEach(([key, value]) => {
            if (key === 'bannerimg' || key === 'coverimg') {
                if (value) {
                    uploadData.append(key, value);
                }
            } else if (key === 'points') {
                value.forEach((point, index) => {
                    if (point.image) {
                        uploadData.append(`image${index + 1}`, point.image);
                    }
                    if (point.heading) {
                        uploadData.append(`heading${index + 1}`, point.heading);
                    }
                    if (point.detail) {
                        uploadData.append(`detail${index + 1}`, point.detail);
                    }
                });
            } else if (key === 'technologyImg') {
                value.forEach((file, index) => {
                    if (file) {
                        uploadData.append(`technologyImg`, file);
                    }
                });
            } else {
                uploadData.append(key, value);
            }
        });

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.post(`${REACT_APP_SERVICE}`, uploadData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${refreshToken}`
                },
            });
            if (response.status === 201) {
                Swal.fire({
                    title: "Service successfully Added",
                    icon: "success"
                });
                resetForm();
                setShowModal(false);
                fetchData();
                window.location.reload(); // Reload page after successful submission (optional)
            }
        } catch (error) {
            Swal.fire({
                title: "Ohh! Failed to save Service Data. Try again!",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error"
            });
        } finally {
            setButtonLoader(false); // Hide BeatLoader
        }
    };

    const handleEdit = (id) => {
        const selectedService = servicedata.find(item => item._id === id);
        setSelectedService(selectedService);
        setFormData({
            ...selectedService,
            s_no: selectedService.s_no,
            bannerimg: selectedService.bannerimg,
            coverimg: selectedService.coverimg,
            title: selectedService.title,
            description: selectedService.description,
            sub_title: selectedService.sub_title,
            sub_description: selectedService.sub_description,
            points: selectedService.points.map(entry => ({
                heading: entry.heading,
                detail: entry.detail,
                image: entry.image || null,
            })),
            technologyImg: selectedService.technologyImg || [],
        });
        setShowServiceModal(true);
    };

    const handleModifyService = async () => {
        let hasError = false;
        const newErrors = {};

        // Check required fields and update error state
        ['s_no', 'title', 'description', 'sub_title'].forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = true;
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }

        setButtonLoader(true);

        const uploadData = new FormData();
        Object.entries(formData).forEach(([key, value]) => {
            if (key === 'bannerimg' || key === 'coverimg') {
                if (value) {
                    uploadData.append(key, value);
                }
            } else if (key === 'points') {
                value.forEach((point, index) => {
                    if (point.image) {
                        uploadData.append(`image${index + 1}`, point.image);
                    }
                    if (point.heading) {
                        uploadData.append(`heading${index + 1}`, point.heading);
                    }
                    if (point.detail) {
                        uploadData.append(`detail${index + 1}`, point.detail);
                    }
                });
            } else if (key === 'technologyImg') {
                value.forEach((file, index) => {
                    if (file) {
                        uploadData.append(`technologyImg`, file);
                    }
                });
            } else {
                uploadData.append(key, value);
            }
        });

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.put(`${REACT_APP_SERVICE}${selectedService._id}`, uploadData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${refreshToken}`
                },
            });

            if (response.status === 200) {
                Swal.fire({
                    title: "Blog has been successfully modified",
                    icon: "success"
                });
                setFormData();
                fetchData();
                setShowServiceModal(false);
                // window.location.reload();
            }
        } catch (error) {
            console.error('Error modifying Service:', error);
            Swal.fire({
                title: "Ohh! Failed to modify Service. Try again!",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error"
            });
        } finally {
            setButtonLoader(false); // Hide BeatLoader
        }
    };

    const handleDelete = (id) => {
        confirmDelete(id);
    };

    const confirmDelete = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCloseButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const refreshToken = Cookies.get('refreshToken');
                    const response = await axios.delete(`${REACT_APP_SERVICE}${id}`, {
                        headers: {
                            'Authorization': `Bearer ${refreshToken}`
                        }
                    });

                    if (response.status === 200) {
                        Swal.fire({
                            title: "Deleted!",
                            text: "Your file has been deleted.",
                            icon: "success"
                        });
                    }
                    fetchData();
                    window.location.reload();
                } catch (error) {
                    console.error('Error deleting Project:', error);
                    Swal.fire({
                        title: "Error!",
                        text: error.response?.data?.message || error.message || "An error occurred",
                        icon: "error"
                    });
                }
            }
        })
    };

    const handleEditExistingImage = (e, index, type) => {
        const file = e.target.files[0];
        setFormData(FormData => {
            const updatedImages = [...FormData[type]];
            updatedImages[index] = file; // Replace the existing URL with the new image at the same index
            return {
                ...FormData,
                [type]: updatedImages
            };
        });
    };

    const handleRemoveExistingImage = (type, index) => {
        setFormData(FormData => {
            const updatedImages = [...FormData[type]];
            updatedImages.splice(index, 1);
            return {
                ...FormData,
                [type]: updatedImages
            };
        });
    };

    const handleEditpointExistingImage = (e, index) => {
        const newImage = e.target.files[0];
        const newpoints = [...formData.points];
        newpoints[index].image = newImage;
        setFormData({ ...formData, points: newpoints });
    };

    const handleRemovepointExistingImage = (index) => {
        const newpoints = [...formData.points];
        newpoints[index].image = null;
        setFormData({ ...formData, points: newpoints });
    };
    0.
    const resetForm = () => {
        const nextSerialNumber = getNextSerialNumber();
        setFormData({ s_no: nextSerialNumber, bannerimg: null, coverimg: null, title: '', description: '', sub_title: '', sub_description: '', points: [{ heading: '', detail: '', image: null, }], technologyImg: [] });
        setTechNumField(1);
        setNumField(1);
        setErrors({});

    };

    useEffect(() => {
        if (!loading) {
            const nextSerialNumber = getNextSerialNumber();
            setFormData(prevState => ({
                ...prevState,
                s_no: nextSerialNumber
            }));
        }
    }, [loading]);

    useEffect(() => {
        fetchData();
        resetForm();
    }, []);

    return (
        <>
            {loading ? (
                <Loadimg
                />
            ) : (
                <>
                    {/* Add New Project button */}
                    <div className="relative pb-[100px]">
                        <div type="btn" onClick={() => setShowModal(true)} className="absolute cursor-pointer hover:shadow-inner shadow-sm btn-secondary flex text-white right-0 mt-[15px] gap-2"><span className=''><BsPlusLg /></span>Add Service
                        </div>
                    </div>
                    <section className="services-sec">
                        <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 relative">
                            {servicedata.map((item) => (
                                <div key={item._id} className="group cursor-pointer" data-aos="zoom-in" data-aos-duration="1000">
                                    <div className="group relative overflow-hidden rounded-3xl border border-transparent bg-white transition duration-500 shadow-sm hover:shadow-md dark:dark:bg-[#101926]  dark:border-secondary-400 p-5">
                                        <button type="button" onClick={() => handleEdit(item._id)} data-modal-target="editUserModal" data-modal-show="editUserModal" className="font-medium z-10 flex transition-all duration-300 absolute bg-[white] rounded-full p-2 top-2 right-2 text-blue-600 hover:scale-110">
                                            <BsFillPencilFill />
                                        </button>
                                        <figure className='mb-5 w-12 h-12 grid place-items-center overflow-hidden rounded-full bg-primary-600'>
                                            <img src={item.coverimg} className=" max-w-[31px] object-fill" alt={item.coverimg} />
                                        </figure>
                                        <h4 className='heading dark:text-white mb-3'>{item.title}</h4>
                                        <p className=" line-clamp-3 dark:text-gray-300">{item.description}</p>
                                        <ul className="absolute inset-x-0 -bottom-full flex items-center justify-center gap-12 pb-2 transition-all duration-300 group-hover:-inset-2 group-hover:bg-gradient-to-t group-hover:from-white/70">
                                            <li className='flex justify-center w-2 h-2'>
                                                <button type="button" onClick={() => handleDelete(item._id)} data-modal-target="editUserModal" data-modal-show="editUserModal" className="flex absolute transition-all duration-300 font-medium bottom-6 text-[black] bg-[white] rounded-full p-2 hover:scale-110 hover:underline">
                                                    <FaTrash />
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </section>
                </>
            )}

            {showModal ? (
                <div className="justify-center items-center bg-[#000000a8] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50" data-aos="zoom-out" data-aos-duration="500">
                    <div className="relative rounded-lg overflow-hidden sm:w-[90%] md:w-[90%] lg:max-w-2xl xl:lg:max-w-2xl md:max-w-2xl sm:max-w-2xl">
                        {/*content*/}
                        <div className="border-0 shadow-lg relative text-white flex flex-col w-auto bg-[#374151] outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between rounded-t p-5">
                                <h1 className='pb-2 font-bold text-secondary-500 text-4xl'>
                                    Create a Service
                                </h1>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => { setShowModal(false); resetForm(); }}>
                                    <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">×</span>
                                </button>
                            </div>
                            {/*body*/}
                            <div className="relative px-6 pb-6 flex-auto h-[38rem] overflow-y-scroll overflow-hidden">
                                <div className="mb-4 w-[50%]">
                                    <label className={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`} >
                                        Serial No.*
                                    </label>
                                    <input className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`} type="number" name="s_no" id="s_no" value={formData.s_no} placeholder='Serial no' onChange={(e) => setFormData({ ...formData, s_no: e.target.value })} required />
                                </div>
                                <div className='grid gap-2 sm:grid-cols-2'>
                                    <div className=''>
                                        <label className={`block text-sm font-semibold mb-2 ${errors.coverimg ? 'text-red-500' : 'text-white'}`} >
                                            Cover Image(Card image)*
                                        </label>
                                        <input className={`block p-0.5 w-full mb-0.5 text-gray-900 border rounded border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:placeholder-gray-400  ${errors.coverimg ? 'border-red-500' : 'border-gray-300'}`} type="file" accept="image/*" onChange={(e) => handleFileChange(e, null, 'coverimg')} />
                                    </div>
                                    <div className=''>
                                        <label className={`block text-sm font-semibold mb-2 ${errors.bannerimg ? 'text-red-500' : 'text-white'}`} >
                                            Banner Image(Point inner image)*
                                        </label>
                                        <input className={`block p-0.5 mb-0.5 w-full text-gray-900 border rounded border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:placeholder-gray-400  ${errors.bannerimg ? 'border-red-500' : 'border-gray-300'}`} type="file" accept="image/*" onChange={(e) => handleFileChange(e, null, 'bannerimg')} />
                                    </div>
                                    <div className="">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.title ? 'text-red-500' : 'text-white'}`} >
                                            Title*
                                        </label>
                                        <textarea className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.title ? 'border-red-500' : 'border-gray-300'}`}
                                            type="text"
                                            id="service-heading"
                                            value={formData.title}
                                            placeholder='Heading'
                                            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                        />
                                    </div>
                                    <div className="">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.description ? 'text-red-500' : 'text-white'}`} >
                                            Description*
                                        </label>
                                        <textarea
                                            className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.description ? 'border-red-500' : 'border-gray-300'}`}
                                            type="text"
                                            id="service-heading"
                                            value={formData.description}
                                            placeholder='Heading Description*'
                                            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                        />
                                    </div>
                                    <div className="">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.sub_title ? 'text-red-500' : 'text-white'}`} >
                                            Sub-Title*
                                        </label>
                                        <textarea
                                            className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.sub_title ? 'border-red-500' : 'border-gray-300'}`}
                                            type="text"
                                            id="service-heading"
                                            value={formData.sub_title}
                                            placeholder='Heading'
                                            onChange={(e) => setFormData({ ...formData, sub_title: e.target.value })}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.sub_description ? 'text-red-500' : 'text-white'}`}>
                                            Sub-Description*
                                        </label>
                                        <textarea className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.sub_description ? 'border-red-500' : 'border-gray-300'}`}
                                            type="text"
                                            id="service-heading"
                                            value={formData.sub_description}
                                            placeholder='Heading'
                                            onChange={(e) => setFormData({ ...formData, sub_description: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className='relative border-2 border-[#ea580c] p-2 rounded-lg mb-3'>
                                    <h1 className='absolute -top-[13px] inline px-[8px] left-[15px] bg-[#374151] text-primary-600 font-bold'>Add Your Custom Point in service</h1>
                                    <div className='grid grid-cols-2 gap-3 mt-[8px]'>
                                        {[...Array(numField)].map((_, index) => (
                                            <React.Fragment key={index}>
                                                <div className='mb-4'>
                                                    <label className="block text-black text-semi-bold mb-1" >
                                                        Point banner Image {index + 1}
                                                    </label>
                                                    <input
                                                        className="block p-0.5 mb-0.5 w-full text-gray-900 border rounded border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:border-gray-600 dark:placeholder-gray-400"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(e) => handleFileChange(e, index, 'image')}
                                                    />
                                                </div>
                                                <div className="">
                                                    <label htmlFor={`heading-${index}`} className="block text-black text-sm font-normal mb-2">
                                                        Point Heading {index + 1}
                                                    </label>
                                                    <textarea
                                                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                        id={`heading-${index}`}
                                                        value={formData.points[index]?.heading || ''}
                                                        placeholder='Point Heading'
                                                        onChange={(e) => handleInputChange(e, index, 'heading')}
                                                    />
                                                </div>
                                                <div className="">
                                                    <label htmlFor={`detail-${index}`} className="block text-black text-sm font-normal mb-2">
                                                        Sub Description {index + 1}
                                                    </label>
                                                    <textarea
                                                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                        id={`detail-${index}`}
                                                        placeholder='Point heading dec.'
                                                        value={formData.points[index]?.detail || ''}
                                                        onChange={(e) => handleInputChange(e, index, 'detail')}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                    <div className="flex gap-3 justify-center">
                                        <button
                                            className="btn-primary flex"
                                            onClick={handleAddField}><span>Add Field</span>
                                        </button>
                                        <button
                                            className="btn-secondary flex"
                                            onClick={handleRemoveField}><span className='text-black'>Remove Field</span>
                                        </button>
                                    </div>
                                </div>
                                <div className='relative border-2 border-[#ea580c] mt-5 p-2 rounded-lg'>
                                    <h1 className='absolute -top-[13px] inline px-[8px] left-[15px] bg-[#374151] text-primary-600 font-bold'>Add Your All Technology You provied</h1>
                                    <div className='grid grid-cols-2 gap-3 mt-[8px]'>
                                        {
                                            [...Array(technumField)].map((_, index) => (
                                                <React.Fragment key={index}>
                                                    <div className='mb-4'>
                                                        <label className="block text-black text-semi-bold mb-1" >
                                                            Technology Images
                                                        </label>
                                                        <input className="block p-0.5 w-full mb-0.5 text-gray-900 border rounded border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:border-gray-600 dark:placeholder-gray-400" type="file" accept="image/*" onChange={(e) => handleFileChange(e, index, 'technologyImg')} />
                                                    </div>
                                                </React.Fragment>
                                            ))
                                        }
                                    </div>
                                    <div className="flex gap-3 justify-center">
                                        <button
                                            className="btn-primary flex"
                                            onClick={handleAddTechField}><span>Add Field</span>
                                        </button>
                                        <button
                                            className="btn-secondary flex"
                                            onClick={handleRemoveTechField}><span className='text-black'>Remove Field</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <button className="btn-submit btn text-center text-white p-5 rounded mt-5" onClick={() => { setButtonLoader(true); handleCreate() }} >
                                        {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            ) : null}

            {selectedService && showserviceModal ? (
                <div className="justify-center items-center bg-[#000000a8] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50" data-aos="zoom-out" data-aos-duration="500">
                    <div className="relative rounded-lg overflow-hidden sm:w-[90%] md:w-[90%] lg:max-w-2xl xl:lg:max-w-2xl md:max-w-2xl sm:max-w-2xl">
                        {/*content*/}
                        <div className="border-0 shadow-lg relative text-white flex flex-col w-auto bg-[#374151] outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between rounded-t p-5">
                                <h1 className='pb-2 font-bold text-secondary-500 text-4xl'>
                                    Edit
                                </h1>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => { setShowServiceModal(false); resetForm(); }}>
                                    <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                                        ×
                                    </span>
                                </button>
                            </div>
                            {/*body*/}
                            <div className="relative px-6 pb-6 flex-auto h-[38rem] overflow-y-scroll overflow-hidden">
                                <div className="mb-4 w-[50%]">
                                    <label className={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`} >
                                        Serial No.*
                                    </label>
                                    <input className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`} type="number" name="s_no" id="s_no" value={formData.s_no} placeholder='Serial no' onChange={(e) => setFormData({ ...formData, s_no: e.target.value })} required />
                                </div>
                                <div className='grid gap-2 grid-cols-2'>
                                    <div className=''>
                                        <div className='max-w-12 max-h-12 h-full mb-2'>
                                            <img src={selectedService.coverimg} alt="" className="w-full h-full rounded-lg object-cover" />
                                        </div>
                                        <label className={`block text-sm font-semibold mb-2 ${errors.coverimg ? 'text-red-500' : 'text-white'}`} >
                                            Cover Image(Card image)
                                        </label>
                                        <input className={`block p-0.5 w-full mb-0.5 text-gray-900 border rounded border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:placeholder-gray-400  ${errors.coverimg ? 'border-red-500' : 'border-gray-300'}`} type="file" accept="image/*" onChange={(e) => setFormData({ ...formData, coverimg: e.target.files[0] })} />
                                    </div>
                                    <div className=''>
                                        <div className='max-w-12 max-h-12 h-full mb-2'>
                                            <img src={selectedService.bannerimg} alt="" className="w-full h-full rounded-lg object-cover" />
                                        </div>
                                        <label className={`block text-sm font-semibold mb-2 ${errors.bannerimg ? 'text-red-500' : 'text-white'}`} >
                                            Banner Image(Point inner image)
                                        </label>
                                        <input className={`block p-0.5 mb-0.5 w-full text-gray-900 border rounded border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:placeholder-gray-400  ${errors.bannerimg ? 'border-red-500' : 'border-gray-300'}`} type="file" accept="image/*" onChange={(e) => setFormData({ ...formData, bannerimg: e.target.files[0] })} />
                                    </div>
                                    <div className="">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.title ? 'text-red-500' : 'text-white'}`} >
                                            Title*
                                        </label>
                                        <textarea className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.title ? 'border-red-500' : 'border-gray-300'}`}
                                            type="text"
                                            id="service-heading"
                                            value={formData.title}
                                            placeholder='Heading'
                                            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                        />
                                    </div>
                                    <div className="">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.description ? 'text-red-500' : 'text-white'}`} >
                                            Description*
                                        </label>
                                        <textarea
                                            className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.description ? 'border-red-500' : 'border-gray-300'}`}
                                            type="text"
                                            id="service-heading"
                                            value={formData.description}
                                            placeholder='Heading Description*'
                                            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                        />
                                    </div>
                                    <div className="">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.sub_title ? 'text-red-500' : 'text-white'}`} >
                                            Sub-Title*
                                        </label>
                                        <textarea
                                            className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.sub_title ? 'border-red-500' : 'border-gray-300'}`}
                                            type="text"
                                            id="service-heading"
                                            value={formData.sub_title}
                                            placeholder='Heading'
                                            onChange={(e) => setFormData({ ...formData, sub_title: e.target.value })}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className='block text-sm font-semibold mb-2 ${errors.sub_description text-white'>
                                            Sub-Description
                                        </label>
                                        <textarea className='shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-gray-300'
                                            type="text"
                                            id="service-heading"
                                            value={formData.sub_description}
                                            placeholder='Sub Des.'
                                            onChange={(e) => setFormData({ ...formData, sub_description: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className='relative border-2 border-orange-600 p-2 rounded-lg mb-3'>
                                    <h1 className='absolute -top-[13px] inline px-[8px] left-[15px] bg-[#374151] text-primary-600 font-bold'>Add Your Custom Point in service</h1>
                                    <div className='grid grid-cols-2 gap-3 mt-[8px]'>
                                        {formData.points.map((entry, index) => (
                                            <React.Fragment key={`heading-${index}`}>
                                                <div>
                                                    <div className='mb-4'>
                                                        <label className="block text-black text-semi-bold mb-1">
                                                            Point banner Image {index + 1}
                                                        </label>
                                                        {/* Render existing image if available */}
                                                        {entry.image && (
                                                            <label className="flex  mb-2 relative justify-center rounded-md overflow-hidden" htmlFor={`fileInput-image1-${index}`}>
                                                                <label className='max-w-[4rem] relative max-h-[4rem] h-full'>
                                                                    <input
                                                                        id={`fileInput-image1-${index}`}
                                                                        className='hidden'
                                                                        type="file"
                                                                        accept="image/*"
                                                                        onChange={(e) => handleEditpointExistingImage(e, index, 'image')}
                                                                    />
                                                                    <BsFillPencilFill className='text-blue-500 absolute right-0 top-0 cursor-pointer transition duration-300 ease-in-out hover:scale-125' />
                                                                    <figure className="rounded-md overflow-hidden" key={index}>
                                                                        <img className='h-full w-full object-cover overflow-hidden' src={typeof entry.image === 'string' ? entry.image : URL.createObjectURL(entry.image)} alt="" />
                                                                    </figure>
                                                                </label>
                                                                <button className='absolute bottom-1 text-red-600 transition duration-300 ease-in-out hover:scale-125' type="button" onClick={() => handleRemovepointExistingImage(index, 'image')}><FaTrash /></button>
                                                            </label>
                                                        )}
                                                        <React.Fragment key={`new-field-${index}`}>
                                                            <div>
                                                                <input
                                                                    className="block w-full p-0.5 mb-0.5 text-gray-900 border rounded border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:border-gray-600 dark:placeholder-gray-400"
                                                                    type="file"
                                                                    accept="image/*"
                                                                    onChange={(e) => handleFileChange(e, index, 'image')}
                                                                />
                                                            </div>
                                                        </React.Fragment>
                                                    </div>
                                                    <div className=''>
                                                        <label htmlFor={`Heading-${index + 1}`} className="block text-black text-sm font-normal mb-2">
                                                            Point Heading {index + 1}
                                                        </label>
                                                        <textarea
                                                            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                            type="text"
                                                            id={`heading-${index}`}
                                                            value={entry.heading}
                                                            placeholder='Point Heading'
                                                            onChange={(e) => handleInputChange(e, index, 'heading')}
                                                        />
                                                    </div>
                                                    <div className='mb-4'>
                                                        <label className="block text-black text-sm font-normal mb-2" htmlFor={`description-${index}`}>
                                                            Sub Description {index + 1}
                                                        </label>
                                                        <textarea
                                                            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                            id={`description-${index}`}
                                                            placeholder='Point heading dec.'
                                                            value={entry.detail}
                                                            onChange={(e) => handleInputChange(e, index, 'detail')}
                                                        />
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                    <div className="flex gap-3 justify-center">
                                        <button
                                            className="btn-primary flex"
                                            onClick={handleAddField}><span>Add Field</span>
                                        </button>
                                        <button
                                            className="btn-secondary flex"
                                            onClick={handleRemoveField}><span className='text-black'>Remove Field</span>
                                        </button>
                                    </div>
                                </div>
                                <div className='relative border-2 border-orange-600 mt-5 p-2 rounded-lg'>
                                    <h1 className='absolute -top-[13px] inline px-[8px] left-[15px] bg-[#374151] text-primary-600 font-bold'>Add Your All Technology You provied</h1>
                                    <div className='mb-4'>
                                        {selectedService.technologyImg && selectedService.technologyImg.length > 0 && (
                                            <>
                                                <div className='relative grid grid-cols-4 mt-[8px] gap-3'>
                                                    {selectedService.technologyImg.map((img, index) => (
                                                        <label key={index} className='relative flex justify-center' htmlFor={`fileInput-${index}`}>
                                                            <label className='text-blue-500 absolute right-0 cursor-pointer'>
                                                                <input
                                                                    className='hidden'
                                                                    type="file"
                                                                    accept="image/*"
                                                                    onChange={(e) => handleEditExistingImage(e, index, 'technologyImg')}
                                                                />
                                                                <BsFillPencilFill />
                                                            </label>
                                                            <figure className="w-24 h-24 rounded-md overflow-hidden" key={index}>
                                                                <img className='h-full w-full object-cover overflow-hidden' src={typeof img === 'string' ? img : URL.createObjectURL(img)} alt={`technologyImg ${index}`} />
                                                            </figure>
                                                            <button className='text-red-500 absolute bottom-1.5 items-center cursor-pointer' type="button" onClick={() => handleRemoveExistingImage('technologyImg', index)}><FaTrash /></button>
                                                        </label>
                                                    ))}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className=" grid grid-cols-2">
                                        {[...Array(technumField)].map((_, index) => (
                                            <React.Fragment key={index}>
                                                <div className='transition duration-500 ease-in-out mb-4'>
                                                    <label className="block text-black text-semi-bold mb-1" >
                                                        Technology Images
                                                    </label>
                                                    <input className="block p-0.5 mb-0.5 text-gray-900 border rounded border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:border-gray-600 dark:placeholder-gray-400" type="file" accept="image/*" onChange={(e) => handleFileChange(e, index, 'technologyImg')} />
                                                </div>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                    <div className="flex gap-3 justify-center">
                                        <button
                                            className="btn-primary flex"
                                            onClick={handleAddTechField}><span>Add Field</span>
                                        </button>
                                        <button
                                            className="btn-secondary flex"
                                            onClick={handleRemoveTechField}><span className='text-black'>Remove Field</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <button className="btn-submit btn text-center text-white p-5 rounded mt-5" onClick={() => { setButtonLoader(true); handleModifyService() }} >
                                        {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            ) : null}
        </>
    );
};

export default Service;
