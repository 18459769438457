import React, { useState, useEffect } from 'react';
import PulseLoader from "react-spinners/PulseLoader";
import { BsFillPencilFill, BsPlusLg } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import Loadimg from './loader';
import axios from 'axios';

const REACT_APP_TEAM = process.env.REACT_APP_TEAM;

const Team = () => {
    const [showModal, setShowModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [buttonloader, setButtonLoader] = useState(false);
    const [data, setData] = useState([]);
    const [selectedTeamMemberId, setSelectedTeamMemberId] = useState(null);

    const [errors, setErrors] = useState({});

    const [formData, setFormData] = useState({
        s_no: '',
        dob: '',
        title: '',
        linkedin_id: '',
        position: '',
    });


    const fetchData = async () => {
        try {
            const res = await axios.get(`${REACT_APP_TEAM}all`);
            setData(res.data);
            setLoading(false);
        } catch (error) {
            console.log("Error fetching data:", error);
            setLoading(false);
        }
    };

    const getNextSerialNumber = () => {
        const existingSerialNumbers = data.map(item => item.s_no);
        const maxSerialNumber = Math.max(...existingSerialNumbers);
        return maxSerialNumber !== -Infinity ? maxSerialNumber + 1 : 1;
    };

    useEffect(() => {
        if (!loading) {
            const nextSerialNumber = getNextSerialNumber();
            setFormData(prevState => ({
                ...prevState,
                s_no: nextSerialNumber
            }));
        }
    }, [loading]);

    useEffect(() => {
        fetchData();
    }, []);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleCreate = async () => {
        let hasError = false;
        const newErrors = {};

        ['s_no', 'title', 'dob', 'linkedin_id', 'position'].forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = true;
                hasError = true;
            }
            if (!selectedFile) {
                newErrors['image'] = true;
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }

        setButtonLoader(true);

        const form = new FormData();
        form.append('s_no', formData.s_no);
        form.append('image', selectedFile);
        form.append('title', formData.title);
        form.append('dob', formData.dob);
        form.append('linkedin_id', formData.linkedin_id);
        form.append('position', formData.position);

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.post(`${REACT_APP_TEAM}`, form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${refreshToken}`
                },
            });
            if (response.status === 201) {
                Swal.fire({
                    title: "Team meneber has beeen add successfully.",
                    icon: "success"
                });
            }
            setFormData({
                s_no: '',
                title: '',
                linkedin_id: '',
                dob: '',
                position: '',
                image: null
            })
            setShowModal(false);
            fetchData();
            window.location.reload()
        } catch (error) {
            Swal.fire({
                title: "Ohh!Failed to Add image.",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error"
            });
        } finally {
            setButtonLoader(false); // Hide BeatLoader
        }
    };

    const handleEditing = (id) => {
        const selectData = data.find(item => item._id === id);
        setSelectedTeamMemberId(selectData)
        setFormData(prevState => ({
            ...prevState,
            s_no: selectData.s_no,
            banner_image: selectData.banner_image,
            title: selectData.title,
            linkedin_id: selectData.linkedin_id,
            dob: selectData.dob.split('T')[0],
            position: selectData.position,
        }));
        setEditModal(true);
    };

    const handleEdit = async () => {
        let hasError = false;
        const newErrors = {};

        ['s_no', 'title', 'dob', 'linkedin_id', 'position'].forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = true;
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }

        setButtonLoader(true);

        const form = new FormData();
        if (selectedFile) {
            form.append('image', selectedFile);
        }
        form.append('s_no', formData.s_no);
        form.append('title', formData.title);
        form.append('linkedin_id', formData.linkedin_id);
        form.append('dob', formData.dob);
        form.append('position', formData.position);

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.put(`${REACT_APP_TEAM}${selectedTeamMemberId._id}`, form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${refreshToken}`
                },
            });
            if (response.status === 200) {
                Swal.fire({
                    title: "team member Updated successfully.",
                    icon: "success"
                });
                setFormData({
                    s_no: '',
                    title: '',
                    linkedin_id: '',
                    dob: '',
                    position: '',
                    image: null
                });
                setEditModal(false); // Close the edit modal
                fetchData();
                window.location.reload();
            }
        } catch (error) {
            Swal.fire({
                title: "Ohh! Failed to Update team member.",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error"
            });
        } finally {
            setButtonLoader(false);// Hide BeatLoader
        }
    };

    const handleDelete = (id) => {
        confirmDelete(id);
    };

    const confirmDelete = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const refreshToken = Cookies.get('refreshToken');
                    const response = await axios.delete(`${REACT_APP_TEAM}${id}`, {
                        headers: {
                            'Authorization': `Bearer ${refreshToken}`
                        }
                    });
                    if (response.status === 200) {
                        Swal.fire({
                            title: "Deleted!",
                            text: "Your file has been deleted.",
                            icon: "success"
                        });
                        fetchData();
                        window.location.reload()
                    }
                } catch (error) {
                    console.error('Error While deleteing:', error);
                    Swal.fire({
                        title: "Error!",
                        text: error.response?.data?.message || error.message || "An error occurred",
                        icon: "error"
                    });
                }
            }

        })

    };

    const resetForm = () => {
        const nextSerialNumber = getNextSerialNumber();
        setFormData({ s_no: nextSerialNumber, dob: '', title: '', linkedin_id: '', position: '', });
        setSelectedFile(null);
        setErrors({});
    };

    useEffect(() => {
        fetchData();
    }, []);

    const getCurrentMonth = () => {
        const date = new Date();
        return date.getMonth() + 1; // JavaScript months are 0-11
    };

    const isBirthdayThisMonth = (dob) => {
        const birthMonth = new Date(dob).getMonth() + 1;
        const birthYear = new Date(dob).getFullYear() + 1;
        const currentyear = new Date().getFullYear()
        const totleage = currentyear - birthYear;
        console.log("totleage",totleage)
        return birthMonth === getCurrentMonth();
    };

    return (
        <>
            <div>
                {loading ? (
                    <Loadimg
                    />
                ) : (
                    <>
                        {/* //-------------------------- Add New Post button--------------------// */}
                        <div className="relative pb-[100px]">
                            <div type="btn" onClick={() => setShowModal(true)} className="absolute cursor-pointer hover:shadow-inner shadow-sm btn-secondary flex text-white right-0 mt-[15px] gap-2"><span className=''><BsPlusLg /></span>Add Member
                            </div>
                        </div>
                        {/* //-------------------Add New Post button end here----------------// */}
                        <section className="team_sec">
                            <div className="grid gap-8 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 relative" data-aos="zoom-in" data-aos-duration="1000">
                                {data.map((item) => {
                                    const isBirthday = isBirthdayThisMonth(item.dob);
                                    return (
                                        <div key={item._id} className={`group cursor-pointer rounded-3xl overflow-hidden text-center relative ${isBirthday ? 'card-body1' : ''}`}>
                                            <div className="group relative h-full">
                                                <div className='w-12 h-12 z-10 absolute top-2 right-0'>
                                                    <button type='button'
                                                        onClick={() => handleEditing(item._id)}
                                                        className="font-medium flex bg-[white] rounded-full p-2 text-blue-600 transition-all duration-300 dark:text-blue-500 hover:scale-125 hover:underline">
                                                        <BsFillPencilFill />
                                                    </button>
                                                </div>
                                                <img src={item.banner_image} className="h-full w-full object-cover" alt="" />
                                                {isBirthday && (
                                                    <>
                                                        <div className="images flex justify-center items-center">
                                                            <figure className="left-0 max-h-[200px] max-w-[200px] md:max-h-[150px] md:max-w-[150px] lg:max-h-[150px] xl:max-w-[100px] xl:max-h-[100px] lg:max-w-[150px] bottom-1 lg:bottom-6 absolute">
                                                                <img className="object-fill h-full w-full" src={require('../assets/ballon.png')} alt="" />
                                                            </figure>
                                                            <figure className="right-0 max-h-[200px] max-w-[200px] md:max-h-[150px] md:max-w-[150px] lg:max-h-[150px] lg:max-w-[150px] xl:max-w-[100px] xl:max-h-[100px] bottom-0 lg:bottom-6 absolute">
                                                                <img className="object-fill h-full w-full" src={require('../assets/ballon.png')} alt="" />
                                                            </figure>
                                                            <figure className="birthday-image mx-auto max-h-[200px] max-w-[200px] md:max-h-[150px] md:max-w-[150px] lg:max-h-[150px] lg:max-w-[150px] xl:max-w-[100px] xl:max-h-[100px] bottom-2 absolute">
                                                                <img className="object-fill h-full w-full" src={require('../assets/happybirthday.png')} alt="" />
                                                            </figure>
                                                        </div>
                                                    </>
                                                )}
                                                <ul className="absolute inset-x-0 -bottom-full flex items-center justify-center gap-10 pb-5 transition-all duration-300 group-hover:inset-y-0 group-hover:bg-gradient-to-t group-hover:from-white/70">
                                                    <li className='w-2 h-2'>
                                                        <button type="button" onClick={() => handleDelete(item._id)} className="flex absolute font-medium transition-all duration-300 bottom-6 text-[black] bg-[white] rounded-full p-2 hover:scale-125 hover:underline">
                                                            <FaTrash />
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </section>
                        {/* //------Adding mape method for geting all data form backend--------// */}
                        {/* //------mape method for geting all data form backend end Here--------// */}
                    </>
                )}
            </div>
            {
                showModal ? (
                    <div
                        className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500" >
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full  my-6 mx-auto lg:max-w-xl xl:max-w-xl md:max-w-xl sm:max-w-xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151]  outline-none focus:outline-none p-6">
                                {/*header*/}
                                <div className="flex items-start justify-between rounded-t">
                                    <h4 className='pb-2 font-bold text-secondary-500 text-4xl'>Add Member</h4>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => { setShowModal(false); resetForm(); }}
                                    >
                                        <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative flex-auto">
                                    <div className='div'>
                                        <label className={`block text-sm font-semibold my-2 ${errors.image ? 'text-red-500' : 'text-white'}`}>Add Image*</label>
                                        {/* Input for uploading a new image */}
                                        <input className={`block w-full p-0.5 text-gray-900 mb-1 border-2 rounded border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 dark:placeholder-gray-400 ${errors.image ? 'border-red-500' : 'border-gray-300'}`} type="file" accept="image/*" onChange={handleFileChange} />
                                    </div>
                                    <div className="div">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`}>
                                            Serial No.*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`}
                                            type="number"
                                            id="s_no"
                                            value={formData.s_no}
                                            placeholder='Serial no'
                                            onChange={(e) => setFormData({ ...formData, s_no: e.target.value })}
                                        />
                                    </div>
                                    <div className="div">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.title ? 'text-red-500' : 'text-white'}`}>
                                            Title*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${errors.title ? 'border-red-500' : 'border-gray-300'}`} id="title" type="text" value={formData.title}
                                            onChange={(e) => setFormData({ ...formData, title: e.target.value })} placeholder="Name" />
                                    </div>
                                    <div className="div">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.linkedin_id ? 'text-red-500' : 'text-white'}`} htmlFor="linkedinid">
                                            Linkedin ID*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${errors.linkedin_id ? 'border-red-500' : 'border-gray-300'}`} id="linkedin_id" type="text" placeholder="Add Linkedin id link" value={formData.linkedin_id} onChange={(e) => setFormData({ ...formData, linkedin_id: e.target.value })} />
                                    </div>
                                    <div className="div">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.dob ? 'text-red-500' : 'text-white'}`} htmlFor="birthdate">
                                            Date of Birth*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${errors.dob ? 'border-red-500' : 'border-gray-300'}`} type="date" id="birthdate" value={formData.dob} onChange={(e) => setFormData({ ...formData, dob: e.target.value })}
                                        />
                                    </div>
                                    <div className="div">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.position ? 'text-red-500' : 'text-white'}`} htmlFor="position">
                                            Position*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${errors.position ? 'border-red-500' : 'border-gray-300'}`} id="username" type="text" placeholder="Positon" value={formData.position} onChange={(e) => setFormData({ ...formData, position: e.target.value })} />
                                    </div>
                                    <div className="flex justify-center">
                                        <button onClick={() => { setButtonLoader(true); handleCreate() }} className="btn-submit btn text-center text-white rounded">
                                            {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }
            {
                selectedTeamMemberId && editModal ? (
                    <div
                        className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500" >
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full  my-6 mx-auto lg:max-w-xl xl:max-w-xl md:max-w-xl sm:max-w-xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151]  outline-none focus:outline-none p-6">
                                {/*header*/}
                                <div className="flex items-start justify-between rounded-t">
                                    <h4 className='pb-2 font-bold text-secondary-500 text-4xl'>Edit</h4>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => { setEditModal(false); resetForm(); }}
                                    >
                                        <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative flex-auto">
                                    <div className='div'>
                                        <figure className='w-12 h-12 rounded-md overflow-hidden'>
                                            <img className='h-full w-full object-cover overflow-hidden' src={selectedTeamMemberId.banner_image} alt=''></img>
                                        </figure>
                                        <label className="block text-sm font-semibold my-2">Add Image*</label>
                                        {/* Input for uploading a new image */}
                                        <input className='block w-full p-0.5 mb-2 text-gray-900 border-2 rounded border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 dark:border-gray-600 dark:placeholder-gray-400 ' type="file" accept="image/*" onChange={handleFileChange} />
                                    </div>
                                    <div className="div">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`}>
                                            Serial No.*
                                        </label>
                                        <input
                                            className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`}
                                            type="number"
                                            id="s_no"
                                            value={formData.s_no}
                                            placeholder='Serial no'
                                            onChange={(e) => setFormData({ ...formData, s_no: e.target.value })}
                                        />
                                    </div>
                                    <div className="div">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.title ? 'text-red-500' : 'text-white'}`}>
                                            Title*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${errors.title ? 'border-red-500' : 'border-gray-300'}`} id="title" type="text" value={formData.title}
                                            onChange={(e) => setFormData({ ...formData, title: e.target.value })} placeholder="Name" />
                                    </div>
                                    <div className="div">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.linkedin_id ? 'text-red-500' : 'text-white'}`} htmlFor="linkedinid">
                                            Linkedin ID*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${errors.linkedin_id ? 'border-red-500' : 'border-gray-300'}`} id="linkedin_id" type="text" placeholder="Add inkedin id link" value={formData.linkedin_id} onChange={(e) => setFormData({ ...formData, linkedin_id: e.target.value })} />
                                    </div>
                                    <div className="div">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.dob ? 'text-red-500' : 'text-white'}`} htmlFor="birthdate">
                                            Date of Birth*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${errors.dob ? 'border-red-500' : 'border-gray-300'}`} type="date" id="birthdate" value={formData.dob} onChange={(e) => setFormData({ ...formData, dob: e.target.value })}
                                        />
                                    </div>
                                    <div className="div">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.position ? 'text-red-500' : 'text-white'}`} htmlFor="position">
                                            Position*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${errors.position ? 'border-red-500' : 'border-gray-300'}`} id="username" type="text" placeholder="Positon" value={formData.position} onChange={(e) => setFormData({ ...formData, position: e.target.value })} />
                                    </div>
                                    <div className="flex justify-center">
                                        <button onClick={() => { setButtonLoader(true); handleEdit() }} className="btn-submit btn text-center text-white p-5 rounded">
                                            {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }
        </>
    );
};

export default Team;
