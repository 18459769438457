import React from "react";
import '../assets/css/App.css';// Assuming your CSS file is named Loader.css
import Loader_img from "../assets/CB-logo.png"

const Loading = () => {
    return (
        <>
            <div className="loader">
                <img className="loader-img" src={Loader_img} alt="Loading" />
            </div>
        </>
    );
};

export default Loading;