import React from "react";

// Admin Imports
import Home from "pages/home";
import Blog from "pages/blog";
import Gallery from "pages/gallery";
import FAQs from "pages/faqs";
import Hireing from "pages/hireing";
import Partner from "pages/partner";
import ProjectTechImage from "pages/projectech";
import Project from "pages/project";
import Team from "pages/team";
import Testimonial from 'pages/testimonial';
import Service from 'pages/services';
import Setting from "pages/setting";
import Theme from "pages/theme";
import ContectList from "pages/contectlist";

// Icon Imports
import { AiTwotoneDiff } from "react-icons/ai";
import {
  MdGroupAdd,
} from "react-icons/md";
import {
  FaBloggerB,
  FaImages,
  FaQuoteLeft,
  FaProjectDiagram,
  FaSteamSquare,
  FaCalendarCheck,
  FaStarOfLife,
  FaBuffer,
  FaChalkboardTeacher,
  FaAddressCard,
  FaSun,
  FaHome
} from "react-icons/fa";

const routes = [
  {
    name: "Home",
    layout: "/admin",
    path: "home",
    icon: <FaHome className="h-6 w-6" />,
    component: <Home />,
    secondary: true,
  },
  {
    name: "Portfolio",
    layout: "/admin",
    path: "portfolio",
    icon: <FaProjectDiagram className="h-6 w-6" />,
    component: <Project />,
  },
  {
    name: "Partner",
    layout: "/admin",
    path: "Partner",
    icon: <MdGroupAdd className="h-6 w-6" />,
    component: <Partner />,
    secondary: true,
  },
  {
    name: "Project Tech Image",
    layout: "/admin",
    path: "project-tech-image",
    icon: <AiTwotoneDiff className="h-6 w-6" />,
    component: <ProjectTechImage />,
    secondary: true,
  },
  {
    name: "Hiring",
    layout: "/admin",
    path: "hiring",
    icon: <FaChalkboardTeacher className="h-6 w-6" />,
    component: <Hireing />,
    secondary: true,
  },
  {
    name: "Gallery",
    layout: "/admin",
    path: "gallery",
    icon: <FaImages className="h-6 w-6" />,
    component: <Gallery />,
    secondary: true,
  },
  {
    name: "Testimonials",
    layout: "/admin",
    path: "testimonials",
    icon: <FaCalendarCheck className="h-6 w-6" />,
    component: <Testimonial />,
    secondary: true,
  },
  {
    name: "FAQ's",
    layout: "/admin",
    path: "faqs",
    icon: <FaQuoteLeft className="h-6 w-6" />,
    component: <FAQs />,
    secondary: true,
  },
  {
    name: "Blog",
    layout: "/admin",
    path: "blog",
    icon: <FaBloggerB className="h-6 w-6" />,
    component: <Blog />,
    secondary: true,
  },
  {
    name: "Theme",
    layout: "/admin",
    path: "Theme",
    icon: <FaBuffer className="h-6 w-6" />,
    component: <Theme />,
    secondary: true,
  },
  {
    name: "Contact List",
    layout: "/admin",
    path: "contact-list",
    icon: <FaAddressCard className="h-6 w-6" />,
    component: <ContectList />,
    secondary: true,
  },
  {
    name: "Team",
    layout: "/admin",
    path: "team",
    icon: <FaSteamSquare className="h-6 w-6" />,
    component: <Team />,
    secondary: true,
  },
  {
    name: "Service",
    layout: "/admin",
    path: "service",
    icon: <FaStarOfLife className="h-6 w-6" />,
    component: <Service />,
    secondary: true,
  },
  {
    name: "Setting",
    layout: "/admin",
    path: "setting",
    icon: <FaSun className="h-6 w-6" />,
    component: <Setting />,
    secondary: true,
  }
];

export default routes;
