import React, { useState, useEffect } from 'react';
import PulseLoader from "react-spinners/PulseLoader";
import { BsFillPencilFill } from "react-icons/bs";
import Createicon from "../assets/svg/crete.svg";
import Loadimg from './loader';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import axios from 'axios';

const REACT_APP_CATEGORY_BLOG = "http://localhost:8080/category/blogctg/";
const REACT_APP_CATEGORY_PROJECT = process.env.REACT_APP_CATEGORY_PROJECT;
//const REACT_APP_CATEGORY_BLOG = process.env.REACT_APP_CATEGORY_BLOG;
const REACT_APP_CATEGORY_OPPORTUNITIES = process.env.REACT_APP_CATEGORY_OPPORTUNITIES;
const REACT_APP_PAGENATION = process.env.REACT_APP_PAGENATION;

const Theme = () => {

    const [showModal, setShowModal] = useState(false);
    const [showModalblogcrt, setShowModalBlogCrt] = useState(false);
    const [showModalopptctg, setShowModalOpptCtg] = useState(false);
    const [editModal, setEditModal] = useState(true);
    const [editModalblogcrt, setEditModalBlogCrt] = useState(true);
    const [editopptcgtModal, setEditOpptcgtModal] = useState(false);
    const [buttonloader, setButtonLoader] = useState(false);
    const [loading, setLoading] = useState(true);

    const [projectcategory, setProjectCategory] = useState([]);
    const [blogcategory, setBlogCategory] = useState([]);
    const [opportunitiescategory, setOpportunitiesCategory] = useState([]);
    const [projectcategoryid, setProjectCategoryID] = useState(null);
    const [blogcategoryid, setBlogCategoryID] = useState(null);
    const [opportunitiescategoryid, setOpportunitiesCategoryID] = useState(null);

    const [pageNo, setPageNo] = useState(null);
    const [pageId, setPageId] = useState(null);
    const [newPageNumber, setNewPageNumber] = useState({ Page_no: '' });

    const [errors, setErrors] = useState({});

    const [formData, setFormData] = useState({
        s_no: "",
        name: ""
    });

    const [blogData, setBlogData] = useState({
        s_no: "",
        name: ""
    });

    const [opptctgData, setOpptCtgData] = useState({
        s_no: "",
        name: ""
    });

    const fetchprojectData = async () => {
        try {
            const response = await axios.get(`${REACT_APP_CATEGORY_PROJECT}all`);
            setProjectCategory(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const fetchblogData = async () => {
        try {
            const response = await axios.get(`${REACT_APP_CATEGORY_BLOG}all`);
            setBlogCategory(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const fetchopportunities = async () => {
        try {
            const response = await axios.get(`${REACT_APP_CATEGORY_OPPORTUNITIES}all`);
            setOpportunitiesCategory(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const fetchPageNumber = async () => {
        try {
            const response = await axios.get(`${REACT_APP_PAGENATION}all`);
            setPageNo(response.data[0].Page_no);
            setPageId(response.data[0]._id);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { value } = e.target;
        setNewPageNumber({ ...newPageNumber, value });
    };

    const handleSubmitPageNumber = async () => {
        let hasError = false;
        const newErrors = {};

        if (!newPageNumber.Page_no.trim()) {
            newErrors['newPageNumber'] = true; // Update the error state for the newPageNumber field
            hasError = true;
        } else {
            // Clear the error state if the field is not empty
            newErrors['newPageNumber'] = false;
        }

        setErrors(newErrors); // Update the errors state

        if (hasError) {
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }

        setButtonLoader(true);
        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.put(`${REACT_APP_PAGENATION}${pageId}`, newPageNumber, {
                headers: {
                    'Authorization': `Bearer ${refreshToken}`
                }
            });
            if (response.status === 201) {
                Swal.fire({
                    title: "New page No has been updated successfully",
                    icon: "success"
                });
                setNewPageNumber({ Page_no: '' }); // Clear input field
                fetchPageNumber(); // Fetch updated page number
                window.location.reload();
            }
        } catch (error) {
            Swal.fire({
                title: "Ohh! Failed to Update page no.",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error"
            });
        } finally {
            setButtonLoader(false); // Hide BeatLoader
        }
    };

    const Createprgctg = async () => {
        let hasError = false;
        const newErrors = {};
        ['s_no', 'name'].forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = true;
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }

        setButtonLoader(true);

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.post(`${REACT_APP_CATEGORY_PROJECT}`, formData, {
                headers: {
                    'Authorization': `Bearer ${refreshToken}`
                }
            });

            if (response.status === 201) {
                Swal.fire({
                    title: "New category has been added successfully.",
                    icon: "success"
                });
                resetForm();
                fetchprojectData();
                setShowModal(false);
                window.location.reload();
            }
        } catch (error) {
            Swal.fire({
                title: "Ohh! Failed to create New Category.",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error"
            });
        } finally {
            setButtonLoader(false);// Hide BeatLoader
        }
    };

    const createblogctg = async () => {
        let hasError = false;
        const newErrors = {};
        ['s_no', 'name'].forEach((field) => {
            if (!blogData[field]) {
                newErrors[field] = true;
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return;
        }

        setButtonLoader(true);
        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.post(`${REACT_APP_CATEGORY_BLOG}`, blogData, {
                headers: {
                    'Authorization': `Bearer ${refreshToken}`
                }
            });

            if (response.status === 201) {
                Swal.fire({
                    title: "New Category has been added successfully.",
                    icon: "success"
                });
                resetForm();
                fetchblogData();
                setShowModalBlogCrt();
                window.location.reload();
            }
        } catch (error) {
            Swal.fire({
                title: "Ohh! Failed to create New Category.",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error"
            });
        } finally {
            setButtonLoader(false);// Hide BeatLoader
        }
    }

    const Createopptctg = async () => {
        let hasError = false;
        const newErrors = {};
        ['s_no', 'name'].forEach((field) => {
            if (!opptctgData[field]) {
                newErrors[field] = true;
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }

        setButtonLoader(true);

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.post(`${REACT_APP_CATEGORY_OPPORTUNITIES}`, opptctgData, {
                headers: {
                    'Authorization': `Bearer ${refreshToken}`
                }
            });
            if (response.status === 201) {
                Swal.fire({
                    title: "New category has been added successfully.",
                    icon: "success"
                });
                setOpptCtgData({
                    s_no: "",
                    name: ""
                })
                fetchopportunities();
                setOpptCtgData(false);
                window.location.reload();
            }
        } catch (error) {
            Swal.fire({
                title: "Ohh! Failed to create New Category.",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error"
            });
        } finally {
            setButtonLoader(false);// Hide BeatLoader
        }
    };

    const handlePrgctgID = (id) => {
        const selectedprtag = projectcategory.find(item => item._id === id);
        setProjectCategoryID(selectedprtag._id);
        setFormData(prevState => ({
            ...prevState,
            s_no: selectedprtag.s_no,
            name: selectedprtag.name
        }));
        setEditModal(true);
    };

    const handleblogctgID = (id) => {
        const selectedblogtag = blogcategory.find(item => item._id === id);
        setBlogCategoryID(selectedblogtag._id);
        setBlogData(prevState => ({
            ...prevState,
            s_no: selectedblogtag.s_no,
            name: selectedblogtag.name
        }));
        setEditModalBlogCrt(true);
    }

    const handleOpptctgID = (id) => {
        const selectedopptctg = opportunitiescategory.find(item => item._id === id);
        setOpportunitiesCategoryID(selectedopptctg._id);
        setOpptCtgData(prevState => ({
            ...prevState,
            s_no: selectedopptctg.s_no,
            name: selectedopptctg.name
        }));
        setEditOpptcgtModal(true);
    };

    const handleModifyprojectg = async () => {
        let hasError = false;
        const newErrors = {};
        ['s_no', 'name'].forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = true;
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }

        setButtonLoader(true);
        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.put(`${REACT_APP_CATEGORY_PROJECT}${projectcategoryid}`, formData, {
                headers: {
                    'Authorization': `Bearer ${refreshToken}`
                }
            });
            if (response.status === 201) {
                Swal.fire({
                    title: "Project Category updated successfully.",
                    icon: "success"
                });
                resetForm();
                fetchprojectData();
                setEditModal(false);
                window.location.reload();
            }
        } catch (error) {
            Swal.fire({
                title: "Ohh! Failed to Update Category.",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error"
            });
        } finally {
            setButtonLoader(false);// Hide BeatLoader
        }
    };

    const handleModifyblogctg = async () => {
        let hasError = false;
        const newErrors = {};
        ['s_no', 'name'].forEach((field) => {
            if (!blogData[field]) {
                newErrors[field] = true;
                hasError = true;
            }
        });
        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }
        setButtonLoader(true);
        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.put(`${REACT_APP_CATEGORY_BLOG}${blogcategoryid}`, blogData, {
                headers: {
                    'Authorization': `Bearer ${refreshToken}`
                }
            });
            if (response.status === 201) {
                Swal.fire({
                    title: "Blog Category updated successfully.",
                    icon: "success"
                });
                resetForm();
                fetchblogData();
                setEditModalBlogCrt(false);
                window.location.reload();
            }
        } catch (error) {
            Swal.fire({
                title: "Ohh! Failed to Update Category.",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error"
            });
        } finally {
            setButtonLoader(false);// Hide BeatLoader
        }
    };

    const handleModifyopportunitiesctg = async () => {
        let hasError = false;
        const newErrors = {};
        ['s_no', 'name'].forEach((field) => {
            if (!opptctgData[field]) {
                newErrors[field] = true;
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }

        setButtonLoader(true);

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.put(`${REACT_APP_CATEGORY_OPPORTUNITIES}${opportunitiescategoryid}`, opptctgData, {
                headers: {
                    'Authorization': `Bearer ${refreshToken}`
                }
            })
            if (response.status === 201) {
                Swal.fire({
                    title: "Opportunities Category updated successfully.",
                    icon: "success"
                });
                setProjectCategory({
                    s_no: "",
                    name: ""
                })
                fetchopportunities();
                setEditOpptcgtModal(false);
                window.location.reload();
            }
        } catch (error) {
            Swal.fire({
                title: "Ohh! Failed to Update Category.",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error"
            });
        } finally {
            setButtonLoader(false);// Hide BeatLoader
        }
    };

    const getNextSerialNumber = () => {
        const existingSerialNumbers = projectcategory.map(item => item.s_no);
        const maxSerialNumber = Math.max(...existingSerialNumbers);
        return maxSerialNumber !== -Infinity ? maxSerialNumber + 1 : 1;
    };

    const getnextBlogSerialNumber = () => {
        const existingSerialNumbers = blogcategory.map(item => item.s_no);
        const maxSerialNumber = Math.max(...existingSerialNumbers);
        return maxSerialNumber !== -Infinity ? maxSerialNumber + 1 : 1;
    }

    const getNextOpptcgtSerialNumber = () => {
        const existingOpptctgSerialNumbers = opportunitiescategory.map(item => item.s_no);
        const maxOpptctgSerialNumbers = Math.max(...existingOpptctgSerialNumbers);
        return maxOpptctgSerialNumbers !== -Infinity ? maxOpptctgSerialNumbers + 1 : 1;
    };

    const resetForm = () => {
        const nextSerialNumber = getNextSerialNumber();
        setFormData({ s_no: nextSerialNumber, name: '' });
        const nextBlogSerialNumber = getnextBlogSerialNumber();
        setBlogData({ s_no: nextBlogSerialNumber, name: '' });
        const nextOpptcgtSerialNumber = getNextOpptcgtSerialNumber();
        setOpptCtgData({ s_no: nextOpptcgtSerialNumber, name: '' })
        setErrors({});
    };

    useEffect(() => {
        if (!loading) {
            const nextSerialNumber = getNextSerialNumber();
            const nextblogSerialNumber = getnextBlogSerialNumber()
            const nextooptctgSerialNumber = getNextOpptcgtSerialNumber();
            setFormData(prevState => ({
                ...prevState,
                s_no: nextSerialNumber
            }));
            setBlogData(prevState => ({
                ...prevState,
                s_no: nextblogSerialNumber
            }));

            setOpptCtgData(prevState => ({
                ...prevState,
                s_no: nextooptctgSerialNumber
            }));
        }
    }, [loading, blogcategory, opportunitiescategory]);

    useEffect(() => {
        fetchprojectData();
        fetchblogData();
        fetchopportunities();
        fetchPageNumber();
    }, []);

    return (
        <>
            {loading ? (
                <Loadimg />
            ) : (
                <section>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 my-12">
                        <div className="relative border-2 rounded-3xl shadow " data-aos="zoom-in" data-aos-duration="1000">
                            <p className="font-bold dark:text-white text-2xl p-3">
                                Project Category
                            </p>
                            <div className="flex justify-between text-md text-gray-700 uppercase bg-gray-100 dark:bg-gray-900 dark:text-white p-3 [&>*]:text-md font-bold">
                                <h1>Serial No.</h1>
                                <h1>Category Name</h1>
                                <h1>Edit</h1>
                            </div>
                            {projectcategory.map((item) => (
                                <div className="flex justify-between text-black whitespace-now rap dark:text-white px-5 p-3 [&>*]:text-md font-bold" key={item._id} data-aos="zoom-in" data-aos-duration="1000">
                                    <h1>{item.s_no}</h1>
                                    <h1>{item.name}</h1>
                                    <h1 type="btn" onClick={() => handlePrgctgID(item._id)} className='hover:scale-125 transition duration-300 cursor-pointer ease-in-out'><BsFillPencilFill /></h1>
                                </div>
                            ))}
                            <div className="flex text-center justify-center items-center m-4" data-aos="fade-up" data-aos-duration="2000">
                                <div type="btn" className="bg-gray-300 cursor-pointer hover:scale-125 transition duration-300 ease-in-out font-black p-3 rounded-full" onClick={() => setShowModal(true)}>
                                    <img className='' src={Createicon} alt='' />
                                </div>
                            </div>
                        </div>
                        <div className="relative border-2 rounded-3xl shadow" data-aos="zoom-in" data-aos-duration="1000">
                            <p className="font-bold dark:text-white text-2xl p-3">
                                Trending Opportunities Category
                            </p>
                            <div className="flex justify-between text-md text-gray-700 uppercase bg-gray-100 dark:bg-gray-900 dark:text-white p-3 [&>*]:text-md font-bold">
                                <h1>Serial No.</h1>
                                <h1>Category Name</h1>
                                <h1>Edit</h1>
                            </div>
                            {opportunitiescategory.map((item) => (
                                <div className="flex justify-between text-black whitespace-now rap dark:text-white px-5 p-3 [&>*]:text-md font-bold" key={item._id}>
                                    <h1>{item.s_no}</h1>
                                    <h1>{item.name}</h1>
                                    <h1 onClick={() => handleOpptctgID(item._id)} className='hover:scale-125 cursor-pointer transition duration-300 ease-in-out'><BsFillPencilFill /></h1>
                                </div>
                            ))}
                            <div className="flex text-center justify-center items-center m-4" data-aos="fade-up" data-aos-duration="2000">
                                <div type="btn" onClick={() => setShowModalOpptCtg(true)} className="bg-gray-300 cursor-pointer hover:scale-125 transition duration-300 ease-in-out font-black p-3 rounded-full" >
                                    <figure>
                                        <img className='' src={Createicon} alt='' />
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="relative border-2 rounded-3xl shadow" data-aos="zoom-in" data-aos-duration="1000">
                            <p className="font-bold dark:text-white text-2xl p-3">
                                Blog Category
                            </p>
                            <div className="flex justify-between text-md text-gray-700 uppercase bg-gray-100 dark:bg-gray-900 dark:text-white p-3 [&>*]:text-md font-bold">
                                <h1>Serial No.</h1>
                                <h1>Category Name</h1>
                                <h1>Edit</h1>
                            </div>
                            {blogcategory.map((item) => (
                                <div className="flex justify-between text-black whitespace-now rap dark:text-white px-5 p-3 [&>*]:text-md font-bold" key={item._id}>
                                    <h1>{item.s_no}</h1>
                                    <h1>{item.name}</h1>
                                    <h1 onClick={() => handleblogctgID(item._id)} className='hover:scale-125 cursor-pointer transition duration-300 ease-in-out'><BsFillPencilFill /></h1>
                                </div>
                            ))}
                            <div className="flex text-center justify-center items-center m-4" data-aos="fade-up" data-aos-duration="2000">
                                <div type="btn" onClick={() => setShowModalBlogCrt(true)} className="bg-gray-300 cursor-pointer hover:scale-125 transition duration-300 ease-in-out font-black p-3 rounded-full" >
                                    <figure>
                                        <img className='' src={Createicon} alt='' />
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="relative border-2 rounded-3xl shadow overflow-hidden" data-aos="zoom-in" data-aos-duration="1000">
                            <p className="flex justify-between text-md text-gray-700 uppercase overflow-hidden bg-gray-100 dark:bg-gray-900 dark:text-white p-3 [&>*]:text-md font-bold">
                                Customize Pagination Settings
                            </p>
                            {pageNo && (
                                <h4 className="text-md font-bold pl-3 dark:text-white py-4">Total Post on Page: <span>{pageNo}</span></h4>
                            )}
                            <div className="grid grid-cols-1 lg:grid-cols-11 xl:grid-cols-10 font-bold justify-between gap-3 items-center px-3 pb-4">
                                <label className='col-span-3 dark:text-white' htmlFor="page_no">Pagination Customization for Project/Blog:</label>
                                <input
                                    className={`h-[56px] col-span-6 lg:col-span-5 xl:col-span-4 shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.newPageNumber ? 'border-red-500' : 'border-gray-300'}`}
                                    type="number"
                                    id="page_no"
                                    value={newPageNumber.Page_no}
                                    onChange={(e) => setNewPageNumber({ ...newPageNumber, Page_no: e.target.value })}
                                    placeholder='5,10,15,20 and etc.' />
                                <div className="flex justify-center col-span-2 lg:col-span-3 xl:col-span-3">
                                    <button onClick={handleSubmitPageNumber} className="btn-submit btn text-center text-white rounded">
                                        {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            )}
            {
                showModal ? (
                    <div className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-xl xl:max-w-xl md:max-w-xl sm:max-w-xl">
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151]  outline-none focus:outline-none p-5">
                                <div className="flex items-start justify-between rounded-t">
                                    <h4 className='pb-2 font-bold text-secondary-500 text-4xl'>New Category</h4>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => { setShowModal(false); resetForm(); }}
                                    >
                                        <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                <div className="relative flex-auto">
                                    <div className="mb-4">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`}>
                                            Serial No*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`} type="number" name="s_no" value={formData.s_no} onChange={(e) => setFormData({ ...formData, s_no: e.target.value })} required />
                                    </div>
                                    <div className="mb-4">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.name ? 'text-red-500' : 'text-white'}`} htmlFor="title">
                                            Name*
                                        </label>
                                        <input className={`shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.name ? 'border-red-500' : 'border-gray-300'}`} id="title" type="text" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} placeholder="Name" />
                                    </div>
                                    <div className="flex justify-center">
                                        <button onClick={() => { setButtonLoader(true); Createprgctg() }} className="btn-submit btn text-center text-white p-5 rounded">
                                            {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }

            {
                showModalblogcrt ? (
                    <div className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-xl xl:max-w-xl md:max-w-xl sm:max-w-xl">
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151]  outline-none focus:outline-none p-5">
                                <div className="flex items-start justify-between rounded-t">
                                    <h4 className='pb-2 font-bold text-secondary-500 text-4xl'>New Category</h4>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => { setShowModalBlogCrt(false); resetForm(); }}
                                    >
                                        <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                <div className="relative flex-auto">
                                    <div className="mb-4">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`}>
                                            Serial No*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`} type="number" name="s_no" value={blogData.s_no} onChange={(e) => setBlogData({ ...blogData, s_no: e.target.value })} required />
                                    </div>
                                    <div className="mb-4">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.name ? 'text-red-500' : 'text-white'}`} htmlFor="title">
                                            Name*
                                        </label>
                                        <input className={`shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.name ? 'border-red-500' : 'border-gray-300'}`} id="title" type="text" value={blogData.name} onChange={(e) => setBlogData({ ...blogData, name: e.target.value })} placeholder="Name" />
                                    </div>
                                    <div className="flex justify-center">
                                        <button onClick={() => { setButtonLoader(true); createblogctg() }} className="btn-submit btn text-center text-white p-5 rounded">
                                            {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }

            {
                showModalopptctg ? (
                    <div className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-2xl xl:max-w-xl md:max-w-xl sm:max-w-xl">
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151]  outline-none focus:outline-none p-5">
                                <div className="flex items-start justify-between rounded-t">
                                    <h4 className='pb-2 font-bold text-secondary-500 text-4xl'>New Category</h4>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => { setShowModalOpptCtg(false); resetForm(); }}
                                    >
                                        <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                <div className="relative flex-auto">
                                    <div className="mb-4">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`}>
                                            Serial No*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`} type="number" name="s_no" value={opptctgData.s_no} onChange={(e) => setOpptCtgData({ ...opptctgData, s_no: e.target.value })} required />
                                    </div>
                                    <div className="mb-4">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.name ? 'text-red-500' : 'text-white'}`} htmlFor="title">
                                            Name*
                                        </label>
                                        <input className={`shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.name ? 'border-red-500' : 'border-gray-300'}`} id="title" type="text" value={opptctgData.name}
                                            onChange={(e) => setOpptCtgData({ ...opptctgData, name: e.target.value })} placeholder="Name" />
                                    </div>
                                    <div className="flex justify-center">
                                        <button onClick={() => { setButtonLoader(true); Createopptctg() }} className="btn-submit btn text-center text-white p-5 rounded">
                                            {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }

            {
                projectcategoryid && editModal ? (
                    <div className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-xl xl:max-w-xl md:max-w-xl sm:max-w-xl">
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151]  outline-none focus:outline-none p-5">
                                <div className="flex items-start justify-between rounded-t">
                                    <h4 className='pb-2 font-bold text-secondary-500 text-4xl'>Edit</h4>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => { setEditModal(false); resetForm(); }}
                                    >
                                        <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                <div className="relative flex-auto">
                                    <div className="mb-4">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`}>
                                            Serial No*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`} type="number" name="s_no" value={formData.s_no} onChange={(e) => setFormData({ ...formData, s_no: e.target.value })} required />
                                    </div>
                                    <div className="mb-4">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.name ? 'text-red-500' : 'text-white'}`}>
                                            Name*
                                        </label>
                                        <input className={`shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.name ? 'border-red-500' : 'border-gray-300'}`} id="title" type="text" value={formData.name}
                                            onChange={(e) => setFormData({ ...formData, name: e.target.value })} placeholder="Name" />
                                    </div>
                                    <div className="flex justify-center">
                                        <button onClick={() => { setButtonLoader(true); handleModifyprojectg() }} className="btn-submit btn text-center text-white p-5 rounded">
                                            {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                ) : null
            }

            {
                blogcategoryid && editModalblogcrt ? (
                    <div className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-xl xl:max-w-xl md:max-w-xl sm:max-w-xl">
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151]  outline-none focus:outline-none p-5">
                                <div className="flex items-start justify-between rounded-t">
                                    <h4 className='pb-2 font-bold text-secondary-500 text-4xl'>Edit</h4>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => { setEditModalBlogCrt(false); resetForm(); }}
                                    >
                                        <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                <div className="relative flex-auto">
                                    <div className="mb-4">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`}>
                                            Serial No*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`} type="number" name="s_no" value={blogData.s_no} onChange={(e) => setBlogData({ ...blogData, s_no: e.target.value })} required />
                                    </div>
                                    <div className="mb-4">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.name ? 'text-red-500' : 'text-white'}`}>
                                            Name*
                                        </label>
                                        <input className={`shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.name ? 'border-red-500' : 'border-gray-300'}`} id="title" type="text" value={blogData.name}
                                            onChange={(e) => setBlogData({ ...blogData, name: e.target.value })} placeholder="Name" />
                                    </div>
                                    <div className="flex justify-center">
                                        <button onClick={() => { setButtonLoader(true); handleModifyblogctg() }} className="btn-submit btn text-center text-white p-5 rounded">
                                            {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                ) : null
            }

            {
                opportunitiescategoryid && editopptcgtModal ? (
                    <div className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-xl xl:max-w-xl md:max-w-xl sm:max-w-xl">
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151]  outline-none focus:outline-none p-5 ">
                                <div className="flex items-start justify-between rounded-t">
                                    <h4 className='pb-2 font-bold text-secondary-500 text-4xl'>Edit</h4>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => { setEditOpptcgtModal(false); resetForm(); }} >
                                        <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                <div className="relative flex-auto">
                                    <div className="mb-4">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`}>
                                            Serial No*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`} type="number" name="s_no" value={opptctgData.s_no} onChange={(e) => setOpptCtgData({ ...opptctgData, s_no: e.target.value })} required />
                                    </div>
                                    <div className="mb-4">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.name ? 'text-red-500' : 'text-white'}`} htmlFor="title">
                                            Name*
                                        </label>
                                        <input className={`shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.name ? 'border-red-500' : 'border-gray-300'}`} id="title" type="text" value={opptctgData.name}
                                            onChange={(e) => setOpptCtgData({ ...opptctgData, name: e.target.value })} placeholder="Name" />
                                    </div>
                                    <div className="flex justify-center">
                                        <button onClick={() => { setButtonLoader(true); handleModifyopportunitiesctg() }} className="btn-submit btn text-center text-white p-5 rounded">
                                            {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }

        </>
    )
};

export default Theme;