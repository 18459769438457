import React, { useState, useEffect } from 'react';
import PulseLoader from "react-spinners/PulseLoader";
import Createicon from "../assets/svg/crete.svg";
import { RxCross2 } from "react-icons/rx";
import { FaCamera } from "react-icons/fa";
import { MdModeEdit, MdOutlineDeleteOutline } from "react-icons/md";
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import Loadimg from './loader';
import axios from 'axios';

const REACT_APP_LOGO = process.env.REACT_APP_WEBLOGO;
const REACT_APP_HEADMENU = process.env.REACT_APP_HEADMENU;
const REACT_APP_HEADSCRIPT = process.env.REACT_APP_HEADSCRIPT;
const REACT_APP_BODYSCRIPT = process.env.REACT_APP_BODYSCRIPT;
const REACT_APP_FOOTERLIST = process.env.REACT_APP_FOOTERLIST;

const Setting = () => {

    const [formData, setFormData] = useState({ s_no: '', option: '', link: '' });
    const [footerlist, setFooterlist] = useState({
        s_no: '',
        heading: '',
        points: [{ subheading: '', link: '' }]
    });
    const [headerData, setHeaderData] = useState({ category: '', script: '' });
    const [bodydata, setBodyData] = useState({ script: '' });

    const [faviconlogo, setFaviconLogo] = useState(null);
    const [headerlogo, setHeaderLogo] = useState(null);
    const [footerlogo, setFooterLogo] = useState(null);
    const [headeropt, setHeaderopt] = useState([]);
    const [footer, setFooter] = useState([]);
    const [header, setHeader] = useState([]);
    const [body, setBody] = useState([]);

    const [showcreateheadermenu, setShowCreateheadermenu] = useState(false);
    const [showfootercreate, setShowfootercreate] = useState(false);
    const [showheaderscriptmetacreate, setShowHeaderScriptMetaCreate] = useState(false);
    const [showbodyscriptcreate, setShowBodyscriptCreate] = useState(false);

    const [selectedwebfaviconlogoId, setSelectedWebFaviconLogoId] = useState(null);
    const [selectedwebheaderlogoId, setSelectedWebHeaderLogoId] = useState(null);
    const [selectedwebfooterlogoId, setSelectedWebFooterLogoId] = useState(null);
    const [selectedHeadermenuId, setSelectedHeadermenuId] = useState(null);
    const [selectedFooterId, setSelectedFooterId] = useState(null);
    const [selectedheaderscriptmetaId, setSelectedHeaderScriptMetaId] = useState(null);
    const [selectedbodyscriptId, setSelectedBodyScriptId] = useState(null);

    const [showfaviconlogoedit, setShowFaviconlogoedit] = useState(false);
    const [showheaderlogoedit, setShowHeaderlogoedit] = useState(false);
    const [showfooterlogoedit, setShowFooterlogoedit] = useState(false);
    const [showheadmenuedit, setShowheadmenuedit] = useState(false);
    const [showfooterlistedit, setShowFooteredit] = useState(false);
    const [showheaderscriptmetaedit, setShowheaderScriptMateEdit] = useState(false);
    const [showbodyscriptedit, setShowbodyScriptEdit] = useState(false);

    const [loading, setLoading] = useState(true);
    const [buttonloader, setButtonLoader] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);

    const [numField, setNumField] = useState(1);

    const [errors, setErrors] = useState({});

    const [previewUrl, setPreviewUrl] = useState(null); // State to hold the preview URL

    const websitefaviconlogoData = async () => {
        try {
            const res = await axios.get(`${REACT_APP_LOGO}favicon/all`);
            setFaviconLogo(res.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const websiteHeaderlogoData = async () => {
        try {
            const res = await axios.get(`${REACT_APP_LOGO}header/logo/all`);
            setHeaderLogo(res.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const websiteFooterlogoData = async () => {
        try {
            const res = await axios.get(`${REACT_APP_LOGO}footer/logo/all`);
            setFooterLogo(res.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchData = async () => {
        try {
            const res = await axios.get(`${REACT_APP_HEADMENU}all`);
            setHeaderopt(res.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const footerdata = async () => {
        try {
            const res = await axios.get(`${REACT_APP_FOOTERLIST}all`)
            setFooter(res.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const Header = async () => {
        try {
            const res = await axios.get(`${REACT_APP_HEADSCRIPT}all`)
            setHeader(res.data);
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    };

    const Body = async () => {
        try {
            const res = await axios.get(`${REACT_APP_BODYSCRIPT}all`)
            setBody(res.data);
        } catch (error) {
            console.error('Error fetching data:', error)
        };
    }

    const getNextSerialNumber = () => {
        const existingSerialNumbers = headeropt.map(item => item.s_no);
        const maxSerialNumber = Math.max(...existingSerialNumbers);
        return maxSerialNumber !== -Infinity ? maxSerialNumber + 1 : 1;
    };

    const getNextFooterSerialNumber = () => {
        const existingFooterSerialNumber = footer.map(item => item.s_no);
        const maxFooterSerialNumber = Math.max(...existingFooterSerialNumber);
        return maxFooterSerialNumber !== -Infinity ? maxFooterSerialNumber + 1 : 1;
    }

    useEffect(() => {
        if (!loading) {
            const nextSerialNumber = getNextSerialNumber();
            const nextFooterSerialNumber = getNextFooterSerialNumber();

            setFormData(prevState => ({
                ...prevState,
                s_no: nextSerialNumber
            }));

            setFooterlist(prevState => ({
                ...prevState,
                s_no: nextFooterSerialNumber
            }));
        }
    }, [loading, headeropt, footer]);

    //---------------------geting all Data from backend end --------------------//

    const handleInputChange = (index, key, e) => {
        const { value } = e.target;
        setFooterlist(prevState => {
            const updatedPoints = [...prevState.points];
            updatedPoints[index] = { ...updatedPoints[index], [key]: value };
            return { ...prevState, points: updatedPoints };
        });

        setFormData(prevFormData => ({ ...prevFormData, value: value }));
        setHeaderData(prevFormData => ({ ...prevFormData, value: value }));
        setBodyData(prevFormData => ({ ...prevFormData, value: value }));
    };

    useEffect(() => {
        websitefaviconlogoData();
        websiteHeaderlogoData();
        websiteFooterlogoData();
        footerdata();
        fetchData();
        Header();
        Body();
    }, []);

    const handleFileChange = (event, fileType) => {
        const file = event.target.files[0];
        setSelectedFile(file);

        // Generate a preview URL
        const previewUrl = URL.createObjectURL(file);
        setPreviewUrl(previewUrl);

        if (fileType === 'favicon') {
            setSelectedWebFaviconLogoId(faviconlogo._id);
        } else if (fileType === 'header') {
            setSelectedWebHeaderLogoId(headerlogo._id);
        } else if (fileType === 'footer') {
            setSelectedWebFooterLogoId(footerlogo._id);
        } else {
            console.error('Invalid file type');
        }
    };

    //----------------------------Creating New data------------------------------------//
    const handleSubmit = async () => {
        let hasError = false;
        const newErrors = {};

        ['s_no', 'option', 'link'].forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = true;
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }

        setButtonLoader(true);

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.post(`${REACT_APP_HEADMENU}`, formData, {
                headers: {
                    'Authorization': `Bearer ${refreshToken}`
                }
            });
            if (response.status === 201) {
                Swal.fire({
                    title: "Your header menu Added successfully.",
                    icon: "success"
                });
                setFormData({ s_no: '', option: '', link: '' });
                setShowCreateheadermenu(false)
                fetchData();
                window.location.reload();
            }
        } catch (error) {
            Swal.fire({
                title: "Ohh!Failed to Add Body script",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error"
            });
        } finally {
            setButtonLoader(false); // Hide BeatLoader
        }
    };

    const headerscriptmeta = async () => {
        let hasError = false;
        const newErrors = {};

        ['script'].forEach((field) => {
            if (!headerData[field]) {
                newErrors[field] = true;
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }

        setButtonLoader(true);

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.post(`${REACT_APP_HEADSCRIPT}`, headerData, {
                headers: {
                    'Authorization': `Bearer ${refreshToken}`
                }
            });
            if (response.status === 201) {
                Swal.fire({
                    title: "Successfully created new header script/Meta tag.",
                    icon: "success"
                });
                setHeaderData({ category: '', script: '' });
                setShowHeaderScriptMetaCreate(false);
                Header()
                window.location.reload();
            }
        } catch (error) {
            Swal.fire({
                title: "Ohh!Failed created new header script/Meta tag.",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error"
            });
        } finally {
            setButtonLoader(false); // Hide BeatLoader
        }
    };

    const handlefooterlistcreate = async () => {

        const updateData = {
            s_no: footerlist.s_no,
            heading: footerlist.heading,
        };

        let hasError = false;
        const newErrors = {};

        ['s_no', 'heading'].forEach((field) => {
            if (!updateData[field]) {
                newErrors[field] = true;
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }

        setButtonLoader(true);

        // Check if footerlist.points is not empty before iterating
        if (footerlist.points.length > 0) {
            // Iterate through each point in the footerlist
            footerlist.points.forEach((point, index) => {
                // Add subheading and link to updateData with appropriate keys
                updateData[`subheading${index + 1}`] = point.subheading;
                updateData[`link${index + 1}`] = point.link;
            });
        }

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.post(`${REACT_APP_FOOTERLIST}`, updateData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${refreshToken}`
                }
            });

            if (response.status === 201) {
                Swal.fire({
                    title: "Your FooterList Added successfully.",
                    icon: "success"
                });
                setFooterlist({
                    s_no: '',
                    heading: '',
                    points: []
                });
                setShowfootercreate(false);
                footerdata();
                window.location.reload();
            }
        } catch (error) {
            console.error('Failed to create footer list:', error);
            Swal.fire({
                title: "Ohh!Failed to Add FooterList.",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error"
            });
        } finally {
            setButtonLoader(false);
        }
    };

    const handlebodyscriptcreate = async () => {
        let hasError = false;
        const newErrors = {};

        ['script'].forEach((field) => {
            if (!bodydata[field]) {
                newErrors[field] = true;
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }

        setButtonLoader(true);

        try {
            const response = await axios.post(`${REACT_APP_BODYSCRIPT}`, bodydata, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${refreshToken}`
                }
            });
            if (response.status === 201) {
                Swal.fire({
                    title: "Your new Body script added successfully.",
                    icon: "success"
                });
                setBodyData({ script: "" });
                setShowBodyscriptCreate(false);
                Body();
                window.location.reload();
            }
        } catch (error) {
            Swal.fire({
                title: "Ohh!Failed to Add Body script",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error"
            });
        } finally {
            setButtonLoader(false); // Hide BeatLoader
        }
    };

    //-------------------------Headleing Edit Id's------------------------------------------//
    const handleEditfaviconlogo = () => {
        const selectedfaviconlogo = faviconlogo;
        setSelectedWebFaviconLogoId(selectedfaviconlogo);
        setShowFaviconlogoedit(true);
    };

    const handleEditheaderlogo = () => {
        const selectedheaderlogo = headerlogo;
        setSelectedWebHeaderLogoId(selectedheaderlogo);
        setShowHeaderlogoedit(true);
    };

    const handleEditfooterlogo = () => {
        const selectedfooterlogo = footerlogo;
        setSelectedWebFooterLogoId(selectedfooterlogo);
        setShowFooterlogoedit(true);
    };

    const handleheaderEditMenuing = (id) => {
        const selectedheadermenu = headeropt.find(item => item._id === id);
        setSelectedHeadermenuId(selectedheadermenu);
        setFormData(prevState => ({
            ...prevState,
            s_no: selectedheadermenu.s_no,
            option: selectedheadermenu.option,
            link: selectedheadermenu.link,
        }));
        setShowheadmenuedit(true);
    };

    const handlefooterlistEditing = (id) => {
        const selectedfooterlist = footer.find(item => item._id === id);
        setSelectedFooterId(selectedfooterlist);

        if (selectedfooterlist) {
            // Map the existing subheadings and links to an array of objects
            const points = selectedfooterlist.points ? selectedfooterlist.points.map((point, index) => ({
                subheading: point[`subheading${index + 1}`] || "", // Ensure to use the correct key
                link: point[`link${index + 1}`] || "" // Ensure to use the correct key
            })) : [];

            setFooterlist({
                s_no: selectedfooterlist.s_no,
                heading: selectedfooterlist.heading,
                points: points
            });
            setShowFooteredit(true);
        } else {
            // Handle the case where selected footer list is not found
            console.error("Footer list not found");
            // You can also show an error message to the user if needed
        }
    };

    const handleheaderscriptmate = (id) => {
        const selectedheaderscript = header.find(item => item._id === id);
        setSelectedHeaderScriptMetaId(selectedheaderscript);
        setHeaderData({
            ...selectedheaderscript,
            category: selectedheaderscript.category,
            script: selectedheaderscript.script
        });
        setShowheaderScriptMateEdit(true);
    };

    const handlebodyscriptEditing = (id) => {
        const selectedbodyscript = body.find(item => item._id === id);
        setSelectedBodyScriptId(selectedbodyscript);
        setBodyData(prevState => ({
            ...prevState,
            s_no: selectedbodyscript.s_no,
            script: selectedbodyscript.script,
        }));
        setShowbodyScriptEdit(true);
    };

    //-------------------------Headleing Edit Id's End------------------------------------------//
    //-------------------------Headleing Editing Id's------------------------------------------//
    const handlewebsitefaviconUpload = async () => {
        if (!selectedwebfaviconlogoId || !selectedFile) {
            alert('Please select an image and a logo ID to upload.');
            setButtonLoader(false);
            return;
        }
        setButtonLoader(true);
        const formData = new FormData();
        formData.append('faviconlogo', selectedFile);

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.put(`${REACT_APP_LOGO}favicon/${selectedwebfaviconlogoId._id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${refreshToken}`
                },
            });

            if (response.status === 201) {
                Swal.fire({
                    title: "Logo Updated successfully.",
                    icon: "success"
                });
                setSelectedFile(null);
                setShowFaviconlogoedit(false);
                websitefaviconlogoData();
                window.location.reload();
            }
        } catch (error) {
            Swal.fire({
                title: "Ohh! Failed to Update Website Logo.",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error"
            });
            setButtonLoader(false);
        } finally {
            setButtonLoader(false);// Hide BeatLoader
        }
    };

    const handlewebsiteheaderUpload = async () => {
        if (!selectedwebheaderlogoId || !selectedFile) {
            alert('Please select an image and a logo ID to upload.');
            setButtonLoader(false);
            return;
        };

        setButtonLoader(true);
        const formData = new FormData();
        formData.append('headerlogo', selectedFile);

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.put(`${REACT_APP_LOGO}header/logo/${selectedwebheaderlogoId._id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${refreshToken}`
                },
            });

            if (response.status === 201) {
                Swal.fire({
                    title: "Logo Updated successfully.",
                    icon: "success"
                });
                setSelectedFile(null);
                setShowHeaderlogoedit(false);
                websiteHeaderlogoData();
                window.location.reload();
            }
        } catch (error) {
            Swal.fire({
                title: "Ohh! Failed to Update Website Header Logo.",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error"
            });
            setButtonLoader(false);
        } finally {
            setButtonLoader(false); // Hide BeatLoader
        }
    };

    const handlewebsitefooterUpload = async () => {
        if (!selectedwebfooterlogoId || !selectedFile) {
            alert('Please select an image and a logo ID to upload.');
            setButtonLoader(false);
            return;
        }
        setButtonLoader(true);
        const formData = new FormData();
        formData.append('footerlogo', selectedFile);

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.put(`${REACT_APP_LOGO}footer/logo/${selectedwebfooterlogoId._id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${refreshToken}`
                },
            });

            // Check if the request was successful
            if (response.status === 201) {
                Swal.fire({
                    title: "Logo Updated successfully.",
                    icon: "success"
                });
                setSelectedFile(null);
                setShowFooterlogoedit(false);
                websiteFooterlogoData();
                window.location.reload();
            }
        } catch (error) {
            Swal.fire({
                title: "Ohh! Failed to Update Footer Website Logo.",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error"
            });
            setButtonLoader(false);
        } finally {
            setButtonLoader(false);// Hide BeatLoader
        }
    };

    const handleheaderEditMenu = async () => {
        let hasError = false;
        const newErrors = {};

        ['s_no', 'option', 'link'].forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = true;
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }
        setButtonLoader(true);

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.put(`${REACT_APP_HEADMENU}/${selectedHeadermenuId._id}`, formData, {
                headers: {
                    'Authorization': `Bearer ${refreshToken}`
                }
            });
            if (response.status === 201) {
                Swal.fire({
                    title: "Meader menu updated successfully.",
                    icon: "success"
                });
                setFormData({ s_no: '', option: '', link: '' });
                setShowheadmenuedit(false);
                setSelectedHeadermenuId(null);
                fetchData();
                window.location.reload();
            }
        } catch (error) {
            Swal.fire({
                title: "Ohh! Failed to Update header menu.",
                icon: "error"
            });
        } finally {
            setButtonLoader(false);// Hide BeatLoader
        }
    };

    const handlebodyEditscript = async () => {
        let hasError = false;
        const newErrors = {};

        ['script'].forEach((field) => {
            if (!bodydata[field]) {
                newErrors[field] = true;
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }

        setButtonLoader(true);
        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.put(`${REACT_APP_BODYSCRIPT}${selectedbodyscriptId._id}`, bodydata, {
                headers: {
                    'Authorization': `Bearer ${refreshToken}`
                }
            });
            if (response.status === 201) {
                Swal.fire({
                    title: "Meader menu updated successfully.",
                    icon: "success"
                });
                setBodyData({ script: '' });
                setShowbodyScriptEdit(false);
                setSelectedBodyScriptId(null);
                Body();
                window.location.reload();
            }
        } catch (error) {
            Swal.fire({
                title: "Ohh! Failed to Update Body Script.",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error"
            });
        } finally {
            setButtonLoader(false);// Hide BeatLoader
        }
    };

    const handleheaderscriptMetaEdit = async () => {
        let hasError = false;
        const newErrors = {};

        ['script'].forEach((field) => {
            if (!headerData[field]) {
                newErrors[field] = true;
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }

        setButtonLoader(true);

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.put(`${REACT_APP_HEADSCRIPT}${selectedheaderscriptmetaId._id}`, headerData, {
                headers: {
                    'Authorization': `Bearer ${refreshToken}`
                }
            });
            if (response.status === 201) {
                Swal.fire({
                    title: "Meader menu updated successfully.",
                    icon: "success"
                });
                setHeaderData({ category: '', script: '' });
                setShowheaderScriptMateEdit(false);
                setSelectedHeaderScriptMetaId(null);
                Header();
                window.location.reload();
            }
        } catch (error) {
            Swal.fire({
                title: "Ohh! Failed to Update header script/mate.",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error"
            });
        } finally {
            setButtonLoader(false);// Hide BeatLoader
        }
    };

    const handlefooterlistEdit = async () => {

        const updateData = {
            s_no: footerlist.s_no,
            heading: footerlist.heading,
            points: footerlist.points.map((entry, index) => ({
                subheading: entry.subheading,
                link: entry.link
            }))
        };

        let hasError = false;
        const newErrors = {};

        ['s_no', 'heading'].forEach((field) => {
            if (!updateData[field]) {
                newErrors[field] = true;
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }

        setButtonLoader(true);

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.put(`${REACT_APP_FOOTERLIST}/${selectedFooterId._id}`, updateData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${refreshToken}`
                }
            });
            if (response.status === 200) {
                Swal.fire({
                    title: "Footer updated successfully.",
                    icon: "success"
                });
                setFooterlist({
                    s_no: '',
                    heading: '',
                    points: []
                });
                setShowfootercreate(false);
                footerdata();
                window.location.reload();
            }
        }
        catch (error) {
            Swal.fire({
                title: "Ohh! Failed to Update FooterList.",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error"
            });
        } finally {
            setButtonLoader(false); // Hide BeatLoader
        }
    };
    //-------------------------Headleing Editing Id's End------------------------------------------//
    //-------------------------Headleing Delete Id's------------------------------------------//
    const handleDelete = (id) => {
        confirmDelete(id);
    };

    const handlefooterlistDelete = (id) => {
        confirmfooterlistDelete(id);
    };

    const handleheaderscriptmatedelete = (id) => {
        confirmheaderscriptmateDelete(id);
    };

    const handlebodyscriptdelete = (id) => {
        confirmbodyscriptDelete(id);
    }
    //-------------------------Headleing Delete Id's end------------------------------------------//
    //-------------------------Headleing Delete confirming Id's----------------------------------//
    const confirmDelete = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const refreshToken = Cookies.get('refreshToken');
                    const response = await axios.delete(`${REACT_APP_HEADMENU}/${id}`, {
                        headers: {
                            Authorization: `Bearer ${refreshToken}`
                        }
                    });
                    if (response.status === 200) {
                        Swal.fire({
                            title: "Deleted!",
                            text: "Your file has been deleted.",
                            icon: "success"
                        });
                        fetchData();
                        window.location.reload()
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: "Failed to delete Header menu.",
                            icon: "error"
                        });
                    }
                } catch (error) {
                    console.error('Error While deleteing:', error);
                    Swal.fire({
                        title: "Error!",
                        text: error.response?.data?.message || error.message || "An error occurred",
                        icon: "error"
                    });
                }
            }
        });
    };

    const confirmfooterlistDelete = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const refreshToken = Cookies.get('refreshToken');
                    const response = await axios.delete(`${REACT_APP_FOOTERLIST}${id}`, {
                        headers: {
                            'Authorization': `Bearer ${refreshToken}`
                        }
                    });
                    if (response.status === 201) {
                        Swal.fire({
                            title: "Deleted!",
                            text: "Your file has been deleted.",
                            icon: "success"
                        });
                        footerdata();
                        window.location.reload()
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: "Failed to delete FooterList.",
                            icon: "error"
                        });
                    }
                } catch (error) {
                    console.error('Error While deleteing:', error);
                    Swal.fire({
                        title: "Error!",
                        text: error.response?.data?.message || error.message || "An error occurred",
                        icon: "error"
                    });
                }
            }
        });
    };

    const confirmheaderscriptmateDelete = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const refreshToken = Cookies.get('refreshToken');
                    const response = await axios.delete(`${REACT_APP_HEADSCRIPT}${id}`, {
                        headers: {
                            'Authorization': `Bearer ${refreshToken}`
                        }
                    });
                    if (response.status === 201) {
                        Swal.fire({
                            title: "Deleted!",
                            text: "Your file has been deleted.",
                            icon: "success"
                        });
                        Header()
                        window.location.reload();
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: "Ohh! Failed to delete Header Script / Meta.",
                            icon: "error"
                        });
                    }
                } catch (error) {
                    console.error('Error While deleteing:', error);
                    Swal.fire({
                        title: "Error!",
                        text: error.response?.data?.message || error.message || "An error occurred",
                        icon: "error"
                    });
                }
            }
        })
    }

    const confirmbodyscriptDelete = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const refreshToken = Cookies.get('refreshToken');
                    const response = await axios.delete(`${REACT_APP_BODYSCRIPT}${id}`, {
                        headers: {
                            'Authorization': `Bearer ${refreshToken}`
                        }
                    });
                    if (response.status === 201) {
                        Swal.fire({
                            title: "Deleted!",
                            text: "Your file has been deleted.",
                            icon: "success"
                        });
                        Header()
                        window.location.reload();
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: "Ohh! Failed to delete Body Script.",
                            icon: "error"
                        });
                    }
                } catch (error) {
                    console.error('Error While deleteing:', error);
                    Swal.fire({
                        title: "Error!",
                        text: "Failed to delete Body Script.",
                        icon: "error"
                    });
                }
            }
        })
    }

    const handleAddField = () => {
        setNumField(numField + 1);
        setFooterlist(prevState => ({
            ...prevState,
            points: [...prevState.points, { subheading: "", link: "" }]
        }));
    };

    const handleRemoveField = (index) => {
        if (numField > 1) {
            setNumField(numField - 1);
            setFooterlist(prevState => {
                const newArr = [...prevState.points];
                newArr.pop();
                return {
                    ...prevState,
                    points: newArr
                }
            });
        } else if (footerlist.points.length > 0) {
            const newArr = [...footerlist.points];
            newArr.pop();
            setFooterlist({ ...footerlist, points: newArr })
        };
    };

    const resetForm = () => {
        const nextSerialNumber = getNextSerialNumber();
        const nextFooterSerialnumber = getNextFooterSerialNumber();
        setFooterlist({ s_no: nextFooterSerialnumber, heading: '', points: [{ subheading: '', link: '' }] });
        setFormData({ s_no: nextSerialNumber, option: '', link: '' });
        setHeaderData({ category: '', script: '' });
        setBodyData({ script: '' });
        setSelectedFile(null);
        setPreviewUrl(null);
        setErrors({});
    };

    //-------------------------Headleing Delete confirming Id's End----------------------------------//
    useEffect(() => {
        setLoading(false)
    }, [])

    return (
        <>
            {loading ? (
                <Loadimg />
            ) : (
                <>
                    <section className='logo-sec'>
                        <div className="p-3 w-full m-auto dark:border shadow-sm items-center justify-center rounded-2xl" data-aos="zoom-in" data-aos-duration="1000">
                            {faviconlogo && (
                                <>
                                    <div className="relative flex h-[70px] w-[70px] mb-4 top-0 rounded-full border-2 dark:border-white border-gray-300 object-fill">
                                        <img className="h-full w-full rounded-full" title="Update a favicon icon" src={faviconlogo.faviconlogo} alt="Logo" />
                                        <button className='flex gap-1 -bottom-1 -right-1 hover:scale-110 transition p-1.5 duration-300 ease-in-out items-center absolute bg-primary-600 rounded-full text-[white]' onClick={() => handleEditfaviconlogo(faviconlogo._id)}><FaCamera /></button>
                                    </div>
                                </>
                            )}
                            <div className="grid grid-cols-1 2xl:grid-cols-2 xl:grid-cols-2 gap-2 w-full [&>*]:item-center [&>*]:relative [&>*]:p-5 [&>*]:border [&>*]:rounded-2xl [&>*]:border-gray-600 dark:[&>*]:border-white">
                                {headerlogo && (
                                    <>
                                        <figure >
                                            <img className="text-[100px]" title="Update a Header logo" src={headerlogo.headerlogo} alt='herder logo' />
                                            <button className='flex gap-1 bottom-2 right-2 hover:scale-110 transition duration-300 ease-in-out items-center absolute bg-primary-600 px-2 rounded-md' onClick={() => handleEditheaderlogo(headerlogo._id)}><span className='text-[white]'><FaCamera /></span>Edit</button>
                                        </figure>
                                    </>
                                )}
                                {footerlogo && (
                                    <>
                                        <figure>
                                            <img className="text-[100px]" title="Update a Footer logo" src={footerlogo.footerlogo} alt='Footer logo' />
                                            <button className='flex gap-1 bottom-2 right-2 hover:scale-110 transition duration-300 ease-in-out items-center absolute bg-primary-600 px-2 rounded-md' onClick={() => handleEditfooterlogo(footerlogo._id)}><span className='text-[white]'><FaCamera /></span>Edit</button>
                                        </figure>
                                    </>
                                )}
                            </div>
                        </div>
                    </section>
                    <section className='list-sec'>
                        <div className="grid grid-cols-1 2xl:grid-cols-2 gap-6 my-12">
                            <div className="relative shadow-sm dark:border rounded-3xl border-white dark:text-white" data-aos="zoom-in" data-aos-duration="2000">
                                <div className=''>
                                    <p className='font-bold text-2xl p-3'>
                                        Header menu
                                    </p>
                                    <div className="grid-flex w-full flex text-md text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-white p-4 justify-between">
                                        <p className="font-bold">
                                            Tag
                                        </p>
                                        <p className="font-bold">
                                            Action
                                        </p>
                                    </div>
                                    {headeropt.map((item) => (
                                        <>
                                            <div className="grid-flex w-full flex p-4 justify-between" key={item._id}>
                                                <p className="font-bold">
                                                    {item.option}
                                                </p>
                                                <div className="font-bold grid-flex flex gap-1 justify-center items-center p-1 text-[28px]">
                                                    <button type="button"
                                                        onClick={() => handleheaderEditMenuing(item._id)} data-modal-target="editUserModal" data-modal-show="editUserModal" className="font-medium text-primary-600 dark:text-blue-500 hover:scale-125 hover:underline">
                                                        <MdModeEdit />
                                                    </button>
                                                    <button type="button"
                                                        onClick={() => handleDelete(item._id)} data-modal-target="editUserModal" data-modal-show="editUserModal" className="font-medium  text-secondary-600 hover:scale-125 hover:underline">
                                                        <  MdOutlineDeleteOutline />
                                                    </button>
                                                </div>
                                            </div>
                                            <hr className='mx-auto w-[98%]'></hr>
                                        </>
                                    ))}
                                    <div className="flex bottom-0 text-center justify-center items-center m-4"> <button type="btn" onClick={() => setShowCreateheadermenu(true)} className="bg-gray-300 dark:text-gray-900 font-exterbold transition-all duration-500 cursor-pointer hover:scale-110 p-2 text-xl rounded-full">
                                        <img className='mx-auto' src={Createicon} alt='create button' />
                                    </button>
                                    </div>
                                </div>
                            </div>
                            <div className="relative shadow-sm dark:border rounded-3xl border-white dark:text-white" data-aos="zoom-in" data-aos-duration="2000">
                                <div className=''>
                                    <p className="font-bold text-2xl p-3">
                                        Footer List
                                    </p>
                                    <div className="grid-flex w-full flex text-md text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-white p-4 justify-between">
                                        <p className="font-bold">
                                            Tag's
                                        </p>
                                        <p className="font-bold">
                                            Action
                                        </p>
                                    </div>
                                </div>
                                {footer.map((item) => (
                                    <>
                                        <div className="grid-flex w-full flex p-4 justify-between" key={item._id}>
                                            <p className="font-bold">
                                                {item.heading}
                                            </p>
                                            <div className="font-bold grid-flex flex gap-1 justify-center items-center p-1 text-[28px]">
                                                <button type="button"
                                                    onClick={() => handlefooterlistEditing(item._id)} data-modal-target="editUserModal" data-modal-show="editUserModal" className="font-medium text-primary-600 dark:text-blue-500 hover:scale-125 hover:underline">
                                                    <MdModeEdit />
                                                </button>
                                                <button type="button"
                                                    onClick={() => handlefooterlistDelete(item._id)} data-modal-target="editUserModal" data-modal-show="editUserModal" className="font-medium  text-secondary-600 hover:scale-125 hover:underline">
                                                    <  MdOutlineDeleteOutline />
                                                </button>
                                            </div>
                                        </div>
                                        <hr className='mx-auto w-[98%]'></hr>
                                    </>
                                ))}
                                <div className="flex justify-between text-black whitespace-now rap dark:text-white px-5 p-3">
                                </div>
                                <div className="flex text-center justify-center items-center m-4">
                                    <button type="btn" onClick={() => setShowfootercreate(true)} className="bg-gray-300 dark:text-gray-900 font-exterbold transition-all duration-500 cursor-pointer hover:scale-110 p-2 text-xl rounded-full">
                                        <img src={Createicon} alt='create button' />
                                    </button>
                                </div>
                            </div>
                            <div className="relative shadow-sm dark:border rounded-3xl border-white dark:text-white" data-aos="zoom-in" data-aos-duration="2000">
                                <div className=''>
                                    <p className="font-bold text-2xl p-3">
                                        Header Script/Meta Tag
                                    </p>
                                    <div className="grid-flex w-full flex text-md text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-white p-4 justify-between">
                                        <p className="font-bold">
                                            Script's / meta
                                        </p>
                                        <div className="flex gap-3">
                                            <p className="font-bold">
                                                category
                                            </p>
                                            <p className="font-bold">
                                                Action
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {header.map((item) => (
                                    <>
                                        <div className="grid-flex w-full flex p-4 justify-between" key={item._id}>
                                            <p className="font-bold sm:w-[50%] w-[78%]">
                                                {item.script}
                                            </p>
                                            <div className="font-bold grid-flex flex gap-3 justify-center items-center">
                                                {headeropt.filter(catItem => catItem.s_no === parseInt(item.category)).map(filteredItem => (
                                                    <p key={filteredItem.s_no} className="font-bold">{filteredItem.option}</p>
                                                ))}
                                                <div className='flex text-[25px] p-1 gap-1'>
                                                    <button type="button"
                                                        onClick={() => handleheaderscriptmate(item._id)} data-modal-target="editUserModal" data-modal-show="editUserModal" className="font-medium text-primary-600 dark:text-blue-500 hover:scale-125 hover:underline">
                                                        <MdModeEdit />
                                                    </button>
                                                    <button type="button"
                                                        onClick={() => handleheaderscriptmatedelete(item._id)} data-modal-target="editUserModal" data-modal-show="editUserModal" className="font-medium  text-secondary-600 hover:scale-125 hover:underline">
                                                        <  MdOutlineDeleteOutline />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className='mx-auto w-[98%]'></hr>
                                    </>
                                ))}
                                <div className="flex justify-between text-black whitespace-now rap dark:text-white px-5 p-3">
                                </div>
                                <div className="flex text-center justify-center items-center m-4"> <button type="btn" onClick={() => setShowHeaderScriptMetaCreate(true)} className="bg-gray-300 dark:text-gray-900 font-exterbold transition-all duration-500 cursor-pointer hover:scale-110 p-2 text-xl rounded-full">
                                    <img src={Createicon} alt='create button' />
                                </button>
                                </div>
                            </div>
                            <div className="relative shadow-sm dark:border rounded-3xl border-white dark:text-white" data-aos="zoom-in" data-aos-duration="2000">
                                <div className=''>
                                    <p className="font-bold text-2xl p-3">
                                        Body Script
                                    </p>
                                    <div className="grid-flex w-full flex text-md text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-white p-4 justify-between">
                                        <p className="font-bold">
                                            Script's
                                        </p>
                                        <p className="font-bold">
                                            Action
                                        </p>
                                    </div>
                                </div>
                                {body.map((item) => (
                                    <>
                                        <div className="grid-flex w-full flex p-4 justify-between" key={item._id}>
                                            <p className="font-bold w-[78%]">
                                                {item.script}
                                            </p>
                                            <div className="font-bold grid-flex flex gap-3 justify-center items-center">
                                                <div className='flex text-[25px] p-1 gap-1'>
                                                    <button type="button"
                                                        onClick={() => handlebodyscriptEditing(item._id)} data-modal-target="editUserModal" data-modal-show="editUserModal" className="font-medium text-primary-600 dark:text-blue-500 hover:scale-125 hover:underline">
                                                        <MdModeEdit />
                                                    </button>
                                                    <button type="button"
                                                        onClick={() => handlebodyscriptdelete(item._id)} data-modal-target="editUserModal" data-modal-show="editUserModal" className="font-medium  text-secondary-600 hover:scale-125 hover:underline">
                                                        <  MdOutlineDeleteOutline />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className='mx-auto w-[98%]'></hr>
                                    </>
                                ))}
                                <div className="flex bottom-0 text-center justify-center items-center m-4"> <button type="btn" onClick={() => setShowBodyscriptCreate(true)} className="bg-gray-300 dark:text-gray-900 font-exterbold transition-all duration-500 cursor-pointer hover:scale-110 p-2 text-xl rounded-full">
                                    <img className='mx-auto' src={Createicon} alt='create button' />
                                </button>
                                </div>
                            </div>
                        </div >
                    </section >
                </ >
            )}
            {
                selectedwebfaviconlogoId && showfaviconlogoedit ? (
                    <div className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full  my-6 mx-auto lg:max-w-sm xl:max-w-sm md:max-w-sm sm:max-w-sm">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151]  outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex justify-between items-center p-5">
                                    <h4 className='font-bold text-secondary-500 text-4xl'>Edit Favicon Icon</h4>
                                    <button className="bg-transparent text-white text-3xl leading-[0.5] font-semibold"
                                        onClick={() => { setShowFaviconlogoedit(false); resetForm(); }}>< RxCross2 />
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="flex items-center justify-center w-full px-6 pb-5">
                                    <div className='relative flex flex-col items-center justify-center border-2 border-dashed w-full h-[200px] rounded-lg border-gray-500'>
                                        {previewUrl ? (
                                            <img className='w-full h-full object-contain' src={previewUrl} alt='' />
                                        ) : (
                                            <div className='flex flex-col items-center justify-center'>
                                                <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                                </svg>
                                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                                <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF</p>
                                                <input id='faviconlogo' accept='image/*' name="faviconlogo" type='file' className='opacity-0 absolute z-30 top-0 left-0 w-full h-full cursor-pointer' onChange={(event) => handleFileChange(event, 'faviconlogo')} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="flex justify-center mb-5">
                                    <button onClick={() => { setButtonLoader(true); handlewebsitefaviconUpload() }} className="btn-submit btn text-center text-white rounded">
                                        {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }

            {
                selectedwebheaderlogoId && showheaderlogoedit ? (
                    <div className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-sm xl:max-w-sm md:max-w-sm sm:max-w-sm">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151]  outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex justify-between items-center p-5">
                                    <h4 className='font-bold text-secondary-500 text-4xl'>Edit Header Logo</h4>
                                    <button className="bg-transparent text-white text-3xl leading-[0.5] font-semibold"
                                        onClick={() => { setShowHeaderlogoedit(false); resetForm(); }}>< RxCross2 />
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="flex items-center justify-center w-full px-6 pb-5">
                                    <div className='relative flex flex-col items-center justify-center border-2 border-dashed w-full h-[200px] rounded-lg border-gray-500'>
                                        {previewUrl ? (
                                            <img src={previewUrl} alt="" className="w-full h-full object-contain" />
                                        ) : (
                                            <div className="flex flex-col items-center justify-center">
                                                <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                                </svg>
                                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                                <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF</p>
                                                <input id='headerlogo' accept='image/*' name="headerlogo" type='file' className='opacity-0 absolute z-30 top-0 left-0 w-full h-full cursor-pointer' onChange={(event) => handleFileChange(event, 'headerlogo')} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="flex justify-center mb-5">
                                    <button onClick={() => { setButtonLoader(true); handlewebsiteheaderUpload() }} className="btn-submit btn text-center text-white rounded">
                                        {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }

            {
                selectedwebfooterlogoId && showfooterlogoedit ? (
                    <div className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full  my-6 mx-auto lg:max-w-sm xl:max-w-sm md:max-w-sm sm:max-w-sm">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151]  outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex justify-between items-center p-5">
                                    <h4 className='font-bold text-secondary-500 text-4xl'>Edit Footer Logo</h4>
                                    <button className="bg-transparent text-white text-3xl leading-[0.5] font-semibold"
                                        onClick={() => { setShowFooterlogoedit(false); resetForm(); }}>< RxCross2 />
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="flex items-center justify-center w-full px-6 pb-5">
                                    <div className='relative flex flex-col items-center justify-center border-2 border-dashed w-full h-[200px] rounded-lg border-gray-500'>
                                        {previewUrl ? (
                                            <img src={previewUrl} alt="" className="w-full h-full object-contain" />
                                        ) : (
                                            <div className="flex flex-col items-center justify-center">
                                                <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                                </svg>
                                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                                <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF</p>
                                                <input id='footerlogo' accept='image/*' name="footerlogo" type='file' className='opacity-0 absolute z-30 top-0 left-0 w-full h-full cursor-pointer' onChange={(event) => handleFileChange(event, 'footerlogo')} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="flex justify-center mb-5">
                                    <button onClick={() => { setButtonLoader(true); handlewebsitefooterUpload() }} className="btn-submit btn text-center text-white rounded">
                                        {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }

            {
                showcreateheadermenu && (
                    <div
                        className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-sm xl:lg:max-w-sm md:max-w-sm sm:max-w-sm">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151] outline-none focus:outline-none p-5">
                                {/*header*/}
                                <div className="flex justify-between items-center">
                                    <h4 className='font-bold text-secondary-500 text-4xl'>Add Tage</h4>
                                    <button className="bg-transparent text-white text-3xl leading-[0.5] font-semibold" onClick={() => { setShowCreateheadermenu(false); resetForm(); }} >< RxCross2 />
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative flex-auto">
                                    <div className="div">
                                        <label className={`block text-sm font-semibold my-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`}>
                                            Serial No*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`} type="number" name="s_no" value={formData.s_no} onChange={(e) => setFormData({ ...formData, s_no: e.target.value })} required />
                                    </div>
                                    <div className="div">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.option ? 'text-red-500' : 'text-white'}`}>
                                            Option*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${errors.option ? 'border-red-500' : 'border-gray-300'}`} type="text" name="option" value={formData.option} onChange={(e) => setFormData({ ...formData, option: e.target.value })} placeholder='Add New Option' required />
                                    </div>
                                    <div className="div">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.link ? 'text-red-500' : 'text-white'}`}>
                                            Link*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${errors.link ? 'border-red-500' : 'border-gray-300'}`} type="text" name="link" value={formData.link} onChange={(e) => setFormData({ ...formData, link: e.target.value })} placeholder='Add Page Link' required />
                                    </div>
                                </div>
                                <div className="flex justify-center mt-5">
                                    <button onClick={() => { setButtonLoader(true); handleSubmit() }} className="btn-submit btn text-center text-white rounded">
                                        {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                showfootercreate && (
                    <div className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-lg xl:max-w-lg md:max-w-lg sm:max-w-lg">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151]  outline-none focus:outline-none p-5">
                                {/*header*/}
                                <div className="flex items-start justify-between rounded-t">
                                    <h4 className='pb-2 font-bold text-secondary-500 text-4xl'>Add Footer List</h4>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => { setShowfootercreate(false); resetForm(); }}
                                    >
                                        <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative flex-auto">
                                    <div className='grid sm:grid-cols-2 lg:grid-cols-2 gap-3'>
                                        <div className="mb-4">
                                            <label className={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`}>
                                                Serial No*
                                            </label>
                                            <input className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`} type="number" name="s_no" value={footerlist.s_no} onChange={(e) => setFooterlist({ ...footerlist, s_no: e.target.value })} required />
                                        </div>
                                        <div className="mb-4">
                                            <label className={`block text-sm font-semibold mb-2 ${errors.heading ? 'text-red-500' : 'text-white'}`}>
                                                Heading*
                                            </label>
                                            <input className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${errors.heading ? 'border-red-500' : 'border-gray-300'}`} type="text" name="heading" value={footerlist.heading} onChange={(e) => setFooterlist({ ...footerlist, heading: e.target.value })} required />
                                        </div>
                                        {
                                            [...Array(numField)].map((_, index) => (
                                                <React.Fragment key={index}>
                                                    <div className="">
                                                        <label className='block text-black text-sm font-normal mb-2'>
                                                            Sub Heading {index + 1}
                                                        </label>
                                                        <input className='mb-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline '
                                                            type="text"
                                                            name={`subheading${index + 1}`}
                                                            value={footerlist.points[index]?.subheading || ""}
                                                            onChange={(e) => handleInputChange(index, 'subheading', e)}
                                                            placeholder='Add a Subheading'
                                                            required
                                                        />
                                                    </div>
                                                    <div className="">
                                                        <label className='block text-black text-sm font-normal mb-2'>
                                                            Link {index + 1}
                                                        </label>
                                                        <input
                                                            className='mb-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'

                                                            type="text"
                                                            name={`link${index + 1}`}
                                                            value={footerlist.points[index]?.link || ""}
                                                            onChange={(e) => handleInputChange(index, 'link', e)}
                                                            placeholder='Add Link of Subheading'
                                                            required
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            ))
                                        }
                                    </div>
                                    <div className="mt-5 flex gap-3 justify-center">
                                        <button
                                            className="btn-primary flex"
                                            onClick={handleAddField}><span>Add Field</span>
                                        </button>
                                        <button
                                            className="btn-secondary flex"
                                            onClick={handleRemoveField}><span className='text-black'>Remove Field</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="flex justify-center mt-5">
                                    <button onClick={() => { setButtonLoader(true); handlefooterlistcreate() }} className="btn-submit btn text-center text-white rounded">
                                        {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                showheaderscriptmetacreate && (
                    <div className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-md xl:max-w-md md:max-w-md">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151]  outline-none focus:outline-none p-5">
                                {/*header*/}
                                <div className="flex items-start justify-between rounded-t">
                                    <h4 className='pb-2 font-bold text-secondary-500 text-4xl'>Add Script/Meta tage </h4>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => { setShowHeaderScriptMetaCreate(false); resetForm(); }}>
                                        <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative flex-auto">
                                    <div className='grid'>
                                        <div className="mb-4">
                                            <label className={`block text-sm font-semibold mb-2 ${errors.script ? 'text-red-500' : 'text-white'}`}>
                                                Script / Meta*
                                            </label>
                                            <textarea className={`mb-2 shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.script ? 'border-red-500' : 'border-gray-300'}`} type="number" name="script" value={headerData.script} onChange={(e) => setHeaderData({ ...headerData, script: e.target.value })} required />
                                        </div>
                                        <div className='w-full justify-between text-[black] bg-[white] font-medium rounded text-sm px-2 py-2 text-center inline-flex items-center'>
                                            <label>Category</label>
                                            <select className='w-full text-center dark:bg-[transparent] focus-visible:outline-none' type="text" name="category" id="category" value={headerData.category} onChange={(e) =>
                                                setHeaderData({ ...headerData, category: e.target.value })}>
                                                <option value="">All Page</option>
                                                {headeropt.map((item) => (
                                                    <option key={item.s_no} value={item.s_no}>{item.option}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-center mt-5">
                                    <button onClick={() => { setButtonLoader(true); headerscriptmeta() }} className="btn-submit btn text-center text-white rounded">
                                        {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div >
                )
            }
            {
                showbodyscriptcreate && (
                    <div
                        className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full  my-6 mx-auto lg:max-w-md xl:max-w-md md:max-w-md sm:max-w-md">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto  bg-[#374151]  outline-none focus:outline-none p-5">
                                {/*header*/}
                                <div className="flex justify-between items-center">
                                    <h4 className='font-bold text-secondary-500  text-4xl'>Add Script</h4>
                                    <button className="bg-transparent text-white text-3xl leading-[0.5] font-semibold" onClick={() => { setShowBodyscriptCreate(false); resetForm(); }} >< RxCross2 />
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative flex-auto">
                                    <div className="mt-2">
                                        <label className={`"block text-black text-sm font-normal mb-2 ${errors.script ? 'text-red-500' : 'text-white'}`}>
                                            Script*
                                        </label>
                                        <textarea className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.script ? 'border-red-500' : 'border-gray-300'}`} type="text" name="link" value={bodydata.script} onChange={(e) => setBodyData({ ...bodydata, script: e.target.value })} placeholder='Add Page Link' required />
                                    </div>
                                </div>
                                <div className="flex justify-center mt-5">
                                    <button onClick={() => { setButtonLoader(true); handlebodyscriptcreate() }} className="btn-submit btn text-center text-white rounded">
                                        {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div >
                )
            }
            {
                selectedHeadermenuId && showheadmenuedit ? (
                    <div className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-sm xl:lg:max-w-sm md:max-w-sm sm:max-w-sm">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151] outline-none focus:outline-none p-5">
                                {/*header*/}
                                <div className="flex items-center justify-between">
                                    <h4 className='font-bold text-secondary-500 text-4xl'>Edit</h4>
                                    <button className="bg-transparent text-white text-3xl leading-[0.5] font-semibold" onClick={() => { setShowheadmenuedit(false); resetForm(); }} >< RxCross2 />
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative flex-auto mt-2">
                                    <div className="div">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`}>
                                            Serial No*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`} type="number" name="s_no" value={formData.s_no} onChange={(e) => setFormData({ ...formData, s_no: e.target.value })} required />
                                    </div>
                                    <div className="div">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.option ? 'text-red-500' : 'text-white'}`}>
                                            Option*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${errors.option ? 'border-red-500' : 'border-gray-300'}`} type="text" name="option" value={formData.option} onChange={(e) => setFormData({ ...formData, option: e.target.value })} placeholder='Add New Option' required />
                                    </div>
                                    <div className="div">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.link ? 'text-red-500' : 'text-white'}`}>
                                            Link*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${errors.link ? 'border-red-500' : 'border-gray-300'}`} type="text" name="link" value={formData.link} onChange={(e) => setFormData({ ...formData, link: e.target.value })} placeholder='Add Page Link' required />
                                    </div>
                                </div>
                                <div className=" flex justify-center">
                                    <button onClick={() => { setButtonLoader(true); handleheaderEditMenu() }} className="btn-submit btn text-center text-white rounded">
                                        {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }
            {
                selectedFooterId && showfooterlistedit ? (
                    <div className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-lg xl:max-w-lg md:max-w-lg sm:max-w-lg">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151] outline-none focus:outline-none p-5">
                                {/*header*/}
                                <div className="flex justify-between items-center mb-2">
                                    <h4 className='font-bold text-secondary-500 text-4xl'>Edit</h4>
                                    <button className="bg-transparent text-white text-3xl leading-[0.5] font-semibold" onClick={() => { setShowFooteredit(false); resetForm(); }} >< RxCross2 />
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative flex-auto">
                                    <div className='grid sm:grid-cols-2 lg:grid-cols-2 gap-3'>
                                        <div className="div">
                                            <label className={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`}>
                                                Serial No*
                                            </label>
                                            <input className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`} type="number" name="s_no" value={footerlist.s_no} onChange={(e) => setFooterlist({ ...footerlist, s_no: e.target.value })} required />
                                        </div>
                                        <div className="div">
                                            <label className={`block text-sm font-semibold mb-2 ${errors.heading ? 'text-red-500' : 'text-white'}`}>
                                                Heading*
                                            </label>
                                            <input className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${errors.heading ? 'border-red-500' : 'border-gray-300'}`} type="text" name="heading" value={footerlist.heading} onChange={(e) => setFooterlist({ ...footerlist, heading: e.target.value })} required />
                                        </div>
                                        {footerlist.points.map((entry, index) => (
                                            <React.Fragment key={index}>
                                                <div className="">
                                                    <label className="block text-black text-sm font-normal mb-2" htmlFor={`subheading${index + 1}`}>
                                                        Sub Heading {index + 1}
                                                    </label>
                                                    <input
                                                        className="mb-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        type="text"
                                                        name={`subheading${index + 1}`}
                                                        value={entry.subheading || ""}
                                                        onChange={(e) => handleInputChange(index, 'subheading', e)}
                                                        placeholder='Add a Subheading'
                                                        required
                                                    />
                                                </div>
                                                <div className="">
                                                    <label className="block text-black text-sm font-normal mb-2" htmlFor={`link${index + 1}`}>
                                                        Link {index + 1}
                                                    </label>
                                                    <input
                                                        className="mb-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        type="text"
                                                        name={`link${index + 1}`}
                                                        value={entry.link || ""}
                                                        onChange={(e) => handleInputChange(index, 'link', e)}
                                                        placeholder='Add Link of Subheading'
                                                        required
                                                    />
                                                </div>
                                            </React.Fragment>
                                        ))
                                        }
                                    </div>
                                    <div className="mt-5 flex gap-3 justify-center">
                                        <button
                                            className="btn-primary flex"
                                            onClick={handleAddField}><span>Add Field</span>
                                        </button>
                                        <button
                                            className="btn-secondary flex"
                                            onClick={handleRemoveField}><span className='text-black'>Remove Field</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="flex justify-center mt-5">
                                    <button onClick={() => { setButtonLoader(true); handlefooterlistEdit() }} className="btn-submit btn text-center text-white rounded">
                                        {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }
            {
                selectedheaderscriptmetaId && showheaderscriptmetaedit ? (
                    <div className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-md xl:max-w-md md:max-w-d+md sm:max-w-md">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151]  outline-none focus:outline-none p-5">
                                {/*header*/}
                                <div className="flex items-start justify-between rounded-t">
                                    <h4 className='pb-2 font-bold text-secondary-500 text-4xl'>Edit</h4>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => { setShowheaderScriptMateEdit(false); resetForm(); }}>
                                        <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative flex-auto">
                                    <div className='grid'>
                                        <div className="div">
                                            <label className={`block text-sm font-semibold mb-2 ${errors.script ? 'text-red-500' : 'text-white'}`}>
                                                Script / Meta*
                                            </label>
                                            <textarea className={`mb-2 shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.script ? 'border-red-500' : 'border-gray-300'}`} type="number" name="script" value={headerData.script} onChange={(e) => setHeaderData({ ...headerData, script: e.target.value })} required />
                                        </div>
                                        <div className='w-full justify-between text-[black] bg-[white] font-medium rounded text-sm p-2 text-center inline-flex items-center'>
                                            <label>Category* </label>
                                            <select className='w-full text-center dark:bg-[transparent] focus-visible:outline-none' type="text" name="category" id="category" value={headerData.category} onChange={(e) =>
                                                setHeaderData({ ...headerData, category: e.target.value })}>
                                                <option value="">All Page</option>
                                                {headeropt.map((item) => (
                                                    <option key={item.s_no} value={item.s_no}>{item.option}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-center mt-5">
                                    <button onClick={() => { setButtonLoader(true); handleheaderscriptMetaEdit() }} className="btn-submit btn text-center text-white rounded">
                                        {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }
            {
                selectedbodyscriptId && showbodyscriptedit ? (
                    <div
                        className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-md xl:max-w-md md:max-w-md sm:max-w-md">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151]  outline-none focus:outline-none p-5" >
                                {/*header*/}
                                <div className="flex justify-between items-center">
                                    <h4 className='font-bold text-secondary-500 text-4xl'>Modify Body Script</h4>
                                    <button className="bg-transparent text-white text-3xl leading-[0.5] font-semibold" onClick={() => { setShowbodyScriptEdit(false); resetForm(); }} >< RxCross2 />
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative flex-auto">
                                    <div className="mt-2">
                                        <label className={`"block text-black text-sm font-normal mb-2 ${errors.script ? 'text-red-500' : 'text-white'}`}>
                                            Script*
                                        </label>
                                        <textarea className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.script ? 'border-red-500' : 'border-gray-300'}`} type="text" name="link" value={bodydata.script} onChange={(e) => setBodyData({ ...bodydata, script: e.target.value })} placeholder='Add Page Link' required />
                                    </div>
                                </div>
                                <div className="flex justify-center mt-5">
                                    <button onClick={() => { setButtonLoader(true); handlebodyEditscript() }} className="btn-submit btn text-center text-white rounded">
                                        {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }
        </>
    );
};


export default Setting;