import React, { useEffect } from "react";
import Cookies from 'js-cookie';
import { BsArrowBarRight } from "react-icons/bs";
import { RiMoonFill, RiSunFill } from "react-icons/ri";

const Navbar = (props) => {
  const { onOpenSidenav, brandText } = props;
  // Initialize darkmode state from local storage or default to true
  const [darkmode, setDarkmode] = React.useState(() => {
    const storedDarkMode = localStorage.getItem("darkmode");
    return storedDarkMode ? storedDarkMode === "true" : true; // Default to true for dark mode
  });

  const logoutAction = (e) => {
    e.preventDefault();
    Object.keys(Cookies.get()).forEach(cookieName => {
      Cookies.remove(cookieName);
    });

    // Redirect to the sign-in page
    window.location.href = "/auth/sign-in";
  };

  useEffect(() => {
    localStorage.setItem("darkmode", darkmode);
    // Update body class based on darkmode
    if (darkmode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [darkmode]);

  return (
    <nav className="top-6 gap-6 w-full flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 mt-3 backdrop-blur-xl dark:bg-[#101926] transition duration-700 ease-in-out">
      <div className="flex gap-[6px] items-center">
        <span
          className="cursor-pointer text-xl pl-3 text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}><BsArrowBarRight className="h-5 w-5" />
        </span>
        <p className="shrink font-bold hover:text-navy-700 dark:hover:text-white text-[30px] lg:text-[45px] xl:text-[45px] 2xl:text-[45px] 3xl:text-[45px] 4xl:text-[45px] capitalize text-navy-700 dark:text-white">
          {brandText}
        </p>
      </div>
      <div className="flex items-center gap-5">
        <div className="relative flex h-[54px] gap-3 flex-grow items-center justify-around rounded-md bg-white shadow-sm dark:!bg-navy-800 dark:shadow-none  md:flex-grow-0">
          <div
            className="cursor-pointer p-3 xl:px-5  text-gray-600"
            onClick={() => setDarkmode(prevMode => !prevMode)}
          >
            {darkmode ? (
              <RiSunFill className="h-8 w-8 text-gray-600  dark:text-white" />
            ) : (
              <RiMoonFill className="h-8 w-8 text-gray-600 dark:text-white" />
            )}
          </div>
        </div>
        <ul className="nav-item font-medium shadow-sm rounded-md  bg-white p-4 dark:!bg-navy-800  text-gray-600">
          <a onClick={(e) => logoutAction(e)} className="nav-link dark:text-white " aria-current="page" href="#">Logout</a>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
