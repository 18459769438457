import { BsArrowBarLeft } from "react-icons/bs";
import Links from "./components/Links";
import axios from "axios";
import { useState, useEffect } from "react";
import routes from "routes.js";

const Sidebar = ({ open, onClose }) => {

  const REACT_APP_LOGO = process.env.REACT_APP_WEBLOGO;
  const [headerlogo, setHeaderLogo] = useState(null);
  

  const websiteHeaderlogoData = async () => {
    try {
      const res = await axios.get(`${REACT_APP_LOGO}header/logo/all`);
      setHeaderLogo(res.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleLinkClick = () => {
    onClose(); // Close the sidebar when a link is clicked
  };

  useEffect(() => {
    websiteHeaderlogoData()
  }, []);

  return (
    <div className={`sm:none duration-175 z-10 overflow-hidden linear fixed h-full bg-white pb-10 shadow-md shadow-white/5 transition-all dark:!bg-[#000000] dark:text-white ${open ? "translate-x-0 transition duration-700 ease-in-out" : "transition duration-700 ease-in-out -translate-x-96"}`}>
      <div className='flex border-b-[1px] px-4 py-9 border-[black] dark:border-white items-center gap-5'>
        {headerlogo && (
          <figure className="max-w-[230px]">
            <img className="w-full h-full " src={headerlogo.headerlogo} />
          </figure>
        )}
        <span className="cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={handleLinkClick}><BsArrowBarLeft className="h-5 w-5" /></span>
      </div>
      <div className="pt-8 h-full overflow-y-scroll scrollbar-hide ">
        <ul className="pb-16">
          <Links routes={routes} handleLinkClick={handleLinkClick} />
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
